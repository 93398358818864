import { CoinbaseWallet } from "@web3-react/coinbase-wallet";
import { initializeConnector, Web3ReactProvider } from "@web3-react/core";
import md5 from 'md5';
import React, { createContext, useContext, useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { MetaMask } from "./metamask-with-mobile-patch";

import { getUserAccount, upsertUserAccount } from "../apis";
import { useNotification } from "../components/Notification/hook";
import store from "../constants/store";
import { PAGE_NAME, STORE_KEYS } from "../constants/store/constant";

class Web3ConnectionStore {
  constructor() {
    const initMetaMask = () => {
      const [metaMask, hooks] = initializeConnector(
        (actions) => new MetaMask({ actions })
      );
      return { name: "metamask", connector: metaMask, hooks };
    };

    const initCoinbase = () => {
      const [coinbaseWallet, hooks] = initializeConnector(
        (actions) => new CoinbaseWallet({ actions, options: {} })
      );
      return { name: "coinbase", connector: coinbaseWallet, hooks };
    };

    this.connectors = {
      metamask: initMetaMask(),
      coinbase: initCoinbase(),
    };
  }

  fromString(cnString) {
    try {
      const props = JSON.parse(cnString);
      const connectorInfo = this.connectors[props?.provider];
      return { ...connectorInfo, chainId: props?.chainId };
    } catch (e) {
      console.log("Failed to parse connectionString", e);
      return {};
    }
  }

  onConnectionStringChanged(newConnectionString) {
    console.log(
      `Web3ConnectionStorage connectionString ${
        newConnectionString ? "updated: " + newConnectionString : "removed"
      }`
    );
  }

  isValid(cnString) {
    if (!cnString) return true;

    try {
      const props = JSON.parse(cnString);
      return props?.provider !== undefined; // && props?.chainId && props?.account
    } catch (e) {
      return false;
    }
  }

  get connectionString() {
    let cnString = null;
    try {
      cnString = localStorage.getItem("web3ConnectorConfig");

      if (cnString && !this.isValid(cnString)) {
        console.warn(
          `Invalid connectionString found in localStorage: ${cnString} -> return undefined`
        );
        return undefined;
      }

      return cnString;
    } catch (e) {
      console.warn(
        `Failed to read web3ConnectorConfig from localStorage value: ${cnString} -> return undefined`,
        e
      );
      return undefined;
    }
  }

  set connectionString(cnString) {
    if (this.connectionString === cnString) return;

    if (!this.isValid(cnString)) {
      console.warn(
        "Attempted to overwrite web3ConnectorConfig with an invalid value: ",
        cnString
      );
      return;
    }

    if (cnString) {
      localStorage.setItem("web3ConnectorConfig", cnString);
    } else {
      localStorage.removeItem("web3ConnectorConfig");
    }

    this.onConnectionStringChanged(cnString);
  }
}

export const MATIC = {
  name: "Matic",
  symbol: "MATIC",
  decimals: 18,
};

export const MAINNET = {
  chainId: 1,
  rpcUrls: [
    process.env.REACT_APP_INFURA_KEY
      ? `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`
      : "",
    "https://cloudflare-eth.com",
  ].filter((url) => url !== ""),
  chainName: "Mainnet",
};

export const POLYGON_MAINNET = {
  chainId: 137,
  rpcUrls: [
    process.env.REACT_APP_INFURA_KEY
      ? `https://polygon-mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`
      : "",
    "https://polygon-rpc.com",
  ].filter((url) => url !== ""),
  chainName: "Polygon Mainnet",
  nativeCurrency: MATIC,
  blockExplorerUrls: ["https://polygonscan.com"],
};

export const POLYGON_MUMBAI = {
  chainId: 80001,
  rpcUrls: [
    process.env.REACT_APP_INFURA_KEY
      ? `https://polygon-mumbai.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`
      : "",
    "https://rpc-mumbai.maticvigil.com/",
  ].filter((url) => url !== ""),
  chainName: "Polygon Mumbai",
  nativeCurrency: MATIC,
  blockExplorerUrls: ["https://mumbai.polygonscan.com"],
};

export const CHAINS = new Map([
  [MAINNET.chainId, MAINNET],
  [POLYGON_MAINNET.chainId, POLYGON_MAINNET],
  [POLYGON_MUMBAI.chainId, POLYGON_MUMBAI],
]);

const DEFAULT_NETWORK = POLYGON_MUMBAI; // window.location.host === 'xyz.com' ? POLYGON_MAINNET : POLYGON_MUMBAI
export const web3ConnectionStore = new Web3ConnectionStore();
const AutoConnectContext = createContext(null);
export const useAutoConnect = () => {
  return useContext(AutoConnectContext);
};

export const ProtectedRoute = ({ children }) => {
  const { account } = useAutoConnect();
  const location = useLocation();

  if (!account) {
    return <Navigate to="/" replace state={{ from: location }} />;
  }

  return children;
};

export const AutoConnectProvider = ({ children }) => {
  const [connectionString, setConnectionString] = useState();
  const [connectorInfo, setConnectorInfo] = useState();
  const [account, setAccount] = useState();
  const [accountInfo, setAccountInfo] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const { displaySuccess, displayError } = useNotification();
  const [, , updateEnterprises] = store.useState(STORE_KEYS.id.enterprises);

  const navigateForExistingUser = (user, account) => {
    const walletAddress = user.wallet_address.trim().toLowerCase()
    const redirectToUserDashOnLogin = (path) => {
      const cPath = path.toLowerCase()

      // extend if more smart redirect paths are necessary
      const isProfilePage = cPath.startsWith('/profile/') || cPath === '/profile'

      return !isProfilePage
    }

    const hash = md5(walletAddress);
    updateEnterprises((prev) => {
      prev.registeredUser = true;
      prev.name = user.name;
      prev.jobTitle = user.job_title;
      prev.username = user.username;
      prev.photo = user.image_url || `https://www.gravatar.com/avatar/${hash}?s=${100}&d=identicon&r=PG`;
      return prev;
    });

    if (redirectToUserDashOnLogin(location?.pathname)) {
      displaySuccess({ message: "Logged in!", timeout: 5000 });
      navigate(`/user/${account}`)
    }
  };

  const navigateToStartNewUser = () => {
    updateEnterprises((prev) => {
      prev.registeredUser = false;
      prev.name = "";
      prev.jobTitle = "";
      prev.username = "";
      prev.photo = "";
      prev.pageName = PAGE_NAME.id.join;
      return prev;
    });
    navigate("/start");
  };

  const navigateToHome = () => {
    updateEnterprises((prev) => {
      prev.registeredUser = false;
      prev.name = "";
      prev.username = "";
      prev.jobTitle = "";
      prev.photo = "";
      return prev;
    });
    navigate("/");
  };

  const loadUser = (account) => {
    let intent = "Login";
    getUserAccount(account)
      .then((res) => {
        if (res.data.exists) {
          setAccountInfo(res.data)
          navigateForExistingUser(res.data, account);
          return;
        }

        intent = "Create user";
        return upsertUserAccount({
          address: account,
          username: "",
          name: "",
          job_title: "",
          image_url: "",
        }).then((res) => {
          displaySuccess({
            message: "New account was created!",
            timeout: 5000,
          });
          navigateToStartNewUser();
        });
      })
      .catch((reason) => {
        console.error(reason);
        displayError({
          message: `${intent} error: ${reason}. Please try again.`,
          timeout: 5000,
        });

        navigateToHome();
      });
  };

  const onConnect = (connectorInfo) => {
    const {
      name,
      chainId,
      connector: {
        provider: { selectedAddress },
      },
    } = connectorInfo;
    const cnStr = JSON.stringify({ provider: name, chainId });

    web3ConnectionStore.connectionString = cnStr;
    setConnectorInfo(connectorInfo);
    setConnectionString(cnStr);
    setAccount(selectedAddress);

    loadUser(selectedAddress);
  };

  const onDisconnect = () => {
    const { name, connector } = connectorInfo;
    if (connector.deactivate) {
      connector.deactivate();
    } else {
      console.log(`Disconnect from ${name} -> Landing`);
    }
    web3ConnectionStore.connectionString = undefined;
    setConnectionString(undefined);
    setConnectorInfo(undefined);
    setAccount(undefined);
    setAccountInfo(undefined);
    updateEnterprises((prev) => {
      prev.enterprises = [];
      return prev;
    });
  };

  useEffect(() => {
    // loadConnectionString
    setConnectionString(web3ConnectionStore.connectionString);
  }, []);

  useEffect(() => {
    // autoConnect
    if (!connectionString || connectorInfo) return;

    const newConnector = web3ConnectionStore.fromString(connectionString);
    let network = CHAINS.get(newConnector.chainId);
    if (!network) {
      network = DEFAULT_NETWORK;
    }

    newConnector.connector
      .activate(network)
      .then(() => {
        const {
          connector: {
            provider: { selectedAddress },
          },
        } = newConnector;

        setConnectorInfo(newConnector);
        setAccount(selectedAddress);
        loadUser(selectedAddress);
      })
      .catch((reason) => {
        console.warn(`Failed to connect to ${connectionString} -> Landing`);
        web3ConnectionStore.connectionString = undefined;
        setConnectionString(undefined);
        setAccount(undefined);

        console.error(reason);
        displayError({
          message: `autoConnect error: ${reason}. Please try again.`,
          timeout: 5000,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionString, connectorInfo]);

  const value = {
    connectors: web3ConnectionStore.connectors,
    network: DEFAULT_NETWORK,
    account: account,
    accountInfo: accountInfo,
    onConnect: onConnect,
    onDisconnect: onDisconnect,
  };

  return (
    <AutoConnectContext.Provider value={value}>
      {connectorInfo ? (
        <Web3ReactProvider
          connectors={[[connectorInfo.connector, connectorInfo.hooks]]}
        >
          {children}
        </Web3ReactProvider>
      ) : (
        children
      )}
    </AutoConnectContext.Provider>
  );
};
