import {
  Box, Button, Divider, IconButton,
  MenuItem,
  Snackbar,
  Typography
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Container } from "@mui/system";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import SwipeableViews from "react-swipeable-views";

import Stack from '@mui/material/Stack';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';


import { HomeAppBar, ModalHead } from "../../components/AppBar";
import { Alert, TransactionDetailModal } from "../../components/Dialog";
import Header from "../../components/Header";
import { DatePicker, Select } from "../../components/Input";
import { useNotification } from "../../components/Notification/hook";
import { TabPanel } from "../../components/Tab";

import { downloadFile } from "../../utils/downloadFile";
import { TransactionList } from "./Dashboard";

import ScrollToTop from "../../components/ScrollToTop";
import store from "../../constants/store";
import { STORE_KEYS, TransactionType, TxAssets, TxCategories } from "../../constants/store/constant";

const SearchCategories = ["All", ...TxCategories];
const isSearchCategory = (val) => SearchCategories.includes(val);

const SearchAssets = ["All", ...TxAssets];
const isSearchAsset = (val) => SearchAssets.includes(val);

const DateType = {
  Start: 0,
  End: 1,
}

const Transactions = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const state = location.state;
  const { wepID, weBack, pTab } = state;
  const { displayWarning } = useNotification();

  const [enterprises] = store.useState(STORE_KEYS.id.enterprises);
  const enterprise = enterprises.tempEnterprise;

  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [category, setCategory] = useState("All");
  const [asset, setAsset] = useState("All");
  const [alert, setAlert] = useState({
    opened: false,
    severity: "success",
    content: "",
    time: 6000,
  });
  const [startDate, setStartDate] = useState(dayjs("2000-01-01"));
  const [endDate, setEndDate] = useState(dayjs());

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleDateChange = (type, startDate, endDate) => (date) => {
    switch (type) {
      case DateType.Start:
        let newStartDate = date.clone().set('hour', 0).set('second', 0).set('millisecond', 0)
        if (newStartDate.isAfter(endDate)) {
          displayWarning({
            message: "Invalid start date. Start date is after end date.",
            timeout: 5000,
          })
        } else {
          setStartDate(newStartDate)
        }
        break;
      case DateType.End:
        let newEndDate = date.clone().set('hour', 23).set('second', 59).set('millisecond', 999)
        if (newEndDate.isBefore(startDate)) {
          displayWarning({
            message: "Invalid end date. End date is before start date.",
            timeout: 5000,
          })
        } else {
          setEndDate(newEndDate)
        }
        break;
      default:
        break;
    }
  }

  const handleSelectChange = (type) => (evt) => {
    const temp = evt.target.value;
    switch (type) {
      case "asset":
        if (isSearchAsset(temp)) setAsset(temp);
        break;
      case "category":
        if (isSearchCategory(temp)) setCategory(temp);
        break;
      default:
        break;
    }
  };

  const handleAlertOpen = (severity, content, time) => {
    setAlert({
      opened: true,
      severity,
      content,
      time,
    });
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlert({
      opened: false,
      severity: "success",
      content: "",
      time: 6000,
    });
  };

  const [transactions, setTrnasactions] = useState([]);

  useEffect(() => {
    setTrnasactions(enterprise.transactions);
  }, [enterprise]);

  const back = () => {
    navigation(`/wepID/dashboard`, { state: { wepID, weBack, pTab } });
  };

  // Tramsaction View Dialog
  const [trxDlgOpen, setTrxDlgOpen] = useState(false);
  const [trxIdx, setTrxIdx] = useState(-1);
  const trxReject = () => {
    setTrxDlgOpen(false);
  };
  const trxAgree = () => {
    setTrxDlgOpen(false);
  };

  const trxClickHandler = (idx) => {
    setTrxDlgOpen(true);
    setTrxIdx(idx);
  };

  const exportToCsv = (e) => {
    e.preventDefault();
    let data = transactions.filter((trx) => {
      const trxDate = dayjs(trx.created_at)
      return startDate.isBefore(trxDate) && endDate.isAfter(trxDate)
    });
    if (category !== "All")
      data = data.filter((trx) => trx.category === category);
    if (asset !== "All") data = data.filter((trx) => trx.asset === asset);
    switch (value) {
      case 0:
        break;
      case 1:
        data = data.filter((trx) => trx.isSend);
        break;
      case 2:
        data = data.filter((trx) => !trx.isSend);
        break;
      default:
        break;
    }

    let headers = ["id,asset,type,category,amount,note,date,to,from"];
    let filename = "";
    switch (value) {
      case 0:
        filename = "transactions.csv";
        break;
      case 1:
        filename = "transactions_sent.csv";
        break;
      case 2:
        filename = "transactions_received.csv";
        break;
      default:
        filename = "transactions.csv";
    }

    // // Headers for each column
    // let headers = ['Id,Name,Surname,Age']

    // Convert users data to a csv
    let usersCsv = data.reduce((acc, trx) => {
      const {
        id,
        asset,
        type,
        category,
        amount,
        note,
        created_at,
        // isSend,
        to,
        from,
      } = trx;
      const createdAt_Date = new Date(created_at).toLocaleString("en-us", {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      })
      acc.push(
        [id, asset, type, category, amount, note, '"' + createdAt_Date + '"', to, from].join(
          ","
        )
      );
      return acc;
    }, []);

    try {
      downloadFile({
        data: [...headers, ...usersCsv].join("\n"),
        fileName: filename,
        fileType: "text/csv",
      });
    } catch (error) {
      handleAlertOpen("Failed", error.toString(), 6000);
    }

    handleAlertOpen("success", "CSV file was exported!", 6000);
  };

  const resetFilter = () => {
    setAsset(SearchAssets[0])
    setCategory(SearchCategories[0])
    if (typeof enterprise.info.created_at === "number") {
      let startDate = dayjs(enterprise.info.created_at * 1000)
      setStartDate(startDate)
    } else {
      setStartDate(dayjs("2000-01-01"))
    }
    let endDate = dayjs().set('hour', 23).set('second', 59).set('millisecond', 999)
    setEndDate(endDate)
  }

  useEffect(() => {
    resetFilter()
  }, [])

  return (
    <>
      <ScrollToTop />
      <Header pageTitle={"Transactions"} />
      <HomeAppBar position="absolute" />
      <Box sx={{ bgcolor: "#E5E5E5", width: "100%", pt: 8.25, }}>
        <ModalHead
          title={enterprise.info.name}
          close={back}
          color='#FFDB0B'
        />
        <Box sx={{ px: 4 }}>
          <Stack direction='row' alignItems='center' justifyContent='center' sx={{ position: 'relative', py: 4 }}>

            <Typography sx={{ fontSize: 16, fontWeight: 600, color: '#28282B' }}>Transactions</Typography>
            <IconButton sx={{ position: 'absolute', right: -8 }} onClick={exportToCsv}>
              <VerticalAlignBottomIcon sx={{ color: '#111827', fontSize: 24 }} />
            </IconButton>
          </Stack>
          <Select
            id="category"
            value={value}
            onChange={handleChange}
            IconComponent={KeyboardArrowDownIcon}
            sx={{
              px: 1,
              fontSize: 14,
              width: '100%',
              fontWeight: 500,
              color: '#FFFFFF',
              bgcolor: '#28282B',
              borderRadius: '6px',
              '& svg': {
                color: '#ffffff'
              }
            }}
          >
            {TransactionType.map((type, idx) => {
              return (
                <MenuItem key={idx} value={idx} sx={{ height: 25 }}>
                  {type}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
        <Divider sx={{ mx: 2, mt: 4 }} />

        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          {[0, 1, 2].map((index, key) => (
            <TabPanel
              key={key}
              value={value}
              index={index}
              dir={theme.direction}
            >
              <Box>
                <Stack spacing={1.25}>
                  <Select
                    id="category"
                    value={category}
                    onChange={handleSelectChange("category")}
                    IconComponent={KeyboardArrowDownIcon}
                    sx={{
                      width: '100%',
                      borderWidth: 0,
                      borderRadius: '6px',
                      '& fieldset': {
                        borderWidth: 0,
                      }
                    }}
                  >
                    {SearchCategories.map((cate, idx) => {
                      return (
                        <MenuItem key={idx} value={cate}>
                          {cate === "All" ? "All Category" : cate}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <Select
                    id="asset"
                    value={asset}
                    onChange={handleSelectChange("asset")}
                    IconComponent={KeyboardArrowDownIcon}
                    sx={{
                      width: '100%',
                      borderWidth: 0,
                      borderRadius: '6px',
                      '& fieldset': {
                        borderWidth: 0,
                      }
                    }}
                  >
                    {SearchAssets.map((ast, idx) => {
                      //possible solution is to remove what is after colon
                      return (
                        <MenuItem key={idx} value={ast}>
                          {(() => {
                            switch (ast) {
                              case "All":
                                return "All Assets";
                              case "TOKEN":
                                return enterprise.info.tokenName;
                              default:
                                return ast;
                            }
                          })()}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <Stack direction='row' spacing={1.25} sx={{ width: '100%' }}>
                    <Box sx={{ width: '50%' }}>
                      <DatePicker
                        sx={{
                          width: '100%',
                          borderWidth: 0,
                          overflow: 'hidden',
                          borderRadius: '6px',
                          '& fieldset': {
                            borderWidth: 0,
                          },
                          '& input': {
                            borderWidth: '0px !important',
                          }
                        }}
                        onChange={handleDateChange(DateType.Start, startDate, endDate)}
                        value={startDate}
                      />
                    </Box>
                    <Box sx={{ width: '50%' }}>
                      <DatePicker
                        sx={{
                          width: '100%',
                          borderWidth: 0,
                          overflow: 'hidden',
                          borderRadius: '6px',
                          '& fieldset': {
                            borderWidth: 0,
                          },
                          '& input': {
                            borderWidth: '0px !important',
                          }
                        }}
                        onChange={handleDateChange(DateType.End, startDate, endDate)}
                        value={endDate}
                      />
                    </Box>
                  </Stack>
                  <Button
                    variant="outlined"
                    onClick={resetFilter}
                    sx={{
                      my: '24px !important',
                      borderWidth: 2,
                      color: '#28282B',
                      borderRadius: 25,
                      borderColor: '#28282B',
                      bgcolor: 'transparent',
                      '&:hover': {
                        borderWidth: 2,
                        color: '#28282B',
                        borderColor: '#28282B',
                        bgcolor: 'transparent',
                      }
                    }}
                  >
                    Reset filter
                  </Button>
                </Stack>
                <Container sx={{ p: 0 }}>
                  <TransactionList
                    transactions={(() => {
                      let temp = transactions.filter((trx) => {
                        const trxDate = dayjs(trx.created_at)
                        return startDate.isBefore(trxDate) && endDate.isAfter(trxDate)
                      });
                      if (category !== "All")
                        temp = temp.filter(
                          (trx) => trx.category === category
                        );
                      if (asset !== "All")
                        temp = temp.filter((trx) => trx.asset === asset);
                      switch (index) {
                        case 0:
                          return temp;
                        case 1:
                          return temp.filter((trx) => trx.isSend);
                        case 2:
                          return temp.filter((trx) => !trx.isSend);
                        default:
                          return temp;
                      }
                    })()}
                    onClickItem={trxClickHandler}
                  />
                </Container>
              </Box>
            </TabPanel>
          ))}
        </SwipeableViews>
      </Box>

      <Snackbar
        open={alert.opened}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity={alert.severity}
          sx={{ width: "100%" }}
        >
          {alert.content}
        </Alert>
      </Snackbar>
      <TransactionDetailModal
        open={trxDlgOpen}
        handleClose={() => setTrxDlgOpen(false)}
        reject={trxReject}
        agree={trxAgree}
        value={transactions[trxIdx]}
        type={
          enterprise.transactions[trxIdx] &&
          enterprise.transactions[trxIdx].type
        }
      />
    </>
  );
};

export default Transactions;
