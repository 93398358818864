import { Box, Button, Grid, Typography } from "@mui/material";
import { useWeb3React } from "@web3-react/core";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { HomeAppBar, ModalHead } from "../AppBar";
import { CreateProcess, JoinProcess } from "../Diagram";
import CarouselItem from "./CarouselItem";
import CarouselTitle from "./CarouselTitle";

const WorldCitizen = () => {
  const sliderRef = React.useRef();
  const { account } = useWeb3React();
  const navigate = useNavigate();

  const slideSpeed = 500;
  const initialSlide = 1;
  const totalSlides = 3;
  const [currentSlide, setCurrentSlide] = useState(initialSlide);
  const [isCreate, setIsCreate] = useState(true);
  const slideSettings = {
    dots: true,
    infinite: true,
    speed: slideSpeed,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: initialSlide,
    adaptiveHeight: false,
    arrows: false,
    customPaging: (index) => {
      return (
        <button
          onClick={() => {
            sliderRef.current.slickGoTo(
              (index + initialSlide) % totalSlides,
              true
            );
          }}
        >
          {`index: ${index}`}
        </button>
      );
    },
    appendDots: (dots) => {
      return (
        <ul
          className="slick-dots"
          style={{
            display: "block",
          }}
        >
          {[0, 1, 2].map((index) => {
            return (
              <li
                key={index}
                className={
                  (index + initialSlide) % totalSlides === currentSlide
                    ? "slick-active"
                    : ""
                }
                onClick={() => {
                  const next = (index + initialSlide) % totalSlides;
                  sliderRef.current.slickGoTo(next, false);
                }}
              >
                <button>{index}</button>
              </li>
            );
          })}
        </ul>
      );
    },
    beforeChange: (current, next) => {
      setTimeout(() => setCurrentSlide(next), slideSpeed);
    },
  };

  const createProfileHandler = () => {
    navigate("/profileInfo");
  };

  const switchHandler = (flag) => () => {
    setIsCreate(flag);
  };

  const closeHandler = () => {
    navigate("/");
  };

  return (
    <>
      <HomeAppBar/>
      <Box
        sx={{
          mt: "12px"
        }}
      >
        <Slider ref={sliderRef} {...slideSettings}>
          <CarouselItem>
            <Grid
              component="h2"
              container
              onClick={switchHandler(!isCreate)}
              sx={{
                width: "100%",
                lineHeight: "30px",
                py: 0,
                mt: 4,
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "15px",
                textAlign: "center",
                borderRadius: "8px",
                border: "1px solid #E3E8EB",
                cursor: "pointer",
              }}
            >
              <Grid
                item
                xs={6}
                component="div"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "8px",
                  backgroundColor: isCreate ? "#3D61B0" : "#ffffff",
                  color: isCreate ? "#ffffff" : "#96A3AA",
                }}
              >
                Create
              </Grid>
              <Grid
                item
                xs={6}
                component="div"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "8px",
                  backgroundColor: isCreate ? "#ffffff" : "#3D61B0",
                  color: isCreate ? "#96A3AA" : "#ffffff",
                }}
              >
                Join
              </Grid>
            </Grid>
            {isCreate && (
              <>
                <Grid item xs={12}>
                  <CarouselTitle>Create Process</CarouselTitle>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ position: "relative", display: "block" }}
                >
                  <CreateProcess />
                </Grid>
              </>
            )}
            {!isCreate && (
              <>
                <Grid item xs={12}>
                  <CarouselTitle>Join Process</CarouselTitle>
                </Grid>
                <Grid item xs={12}>
                  <JoinProcess />
                </Grid>
              </>
            )}
          </CarouselItem>
          <CarouselItem>
            <Grid
              item
              xs={12}
              sx={{
                paddingTop: "0px!important",
              }}
            >
              <CarouselTitle>
                You are you, therefore you are a World Citizen
              </CarouselTitle>
            </Grid>
            <Grid item xs={12}>
              <Box
                component="img"
                src={"/images/join-us.png"}
                alt=""
                sx={{
                  width: "60%",
                  margin: "auto",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: "13px",
                  lineHeight: "15px",
                  fontWeight: "400",
                  textAlign: "center",
                  maxWidth: "800px",
                  display: { xs: "block", md: "none" },
                }}
              >
                Your Wallet Public Address: {account}
                <br />
                <br />
                You are you and therefore you are already a World Citizen. To
                ensure there is only one you, and are more than only your wallet
                public address, claim your citizenship here
              </Typography>
            </Grid>
          </CarouselItem>
          <CarouselItem>
            <Grid
              item
              xs={12}
              sx={{
                paddingTop: "0px!important",
              }}
            >
              <CarouselTitle>
                Components of a <i>World Enterprise</i>
              </CarouselTitle>
            </Grid>
            <Grid item xs={12}>
              <Box
                component="img"
                src={"/images/rotate_hero_3.png"}
                alt=""
                sx={{
                  width: "60%",
                  margin: "auto",
                }}
              />
            </Grid>
          </CarouselItem>
        </Slider>
        <Box
          sx={{
            display: "flex",
            px: "27px",
            my: "12px"
          }}
        >
          <Button
            sx={{
              mx: "auto",
            }}
            onClick={createProfileHandler}
          >
            Continue
          </Button>
        </Box>
      </Box>
      
    </>
  );
};

export default WorldCitizen;
