import { Link } from "@mui/material";
import React from "react";

const Info = ({
  label,
  value,
  type,
  multiline = false,
  textAlign = "right",
  labelColor,
  onClick,
  maxWidth,
  wordBreak = "break-all"
}) => {
  // const openLinkInBrowser = (url: string) => () => {
  //   /* eslint-disable */
  //   if (navigator?.app?.loadUrl) {
  //       navigator.app.loadUrl(url, { openExternal:true });
  //   }
  //   else {
  //       window.open(url, '_system');
  //   }
  // }

  return (
    <div
      style={{
        display: multiline ? "block" : "flex",
        alignItems: multiline ? "start" : "center",
      }}
    >
      <span
        style={{
          textAlign: "left",
          padding: "4px 10px 4px 0",
          paddingRight: "10px",
          color: labelColor == "grayLabel" ? "#A5A5A5" : "black",
          marginBottom: multiline ? "10px" : "0px",
        }}
      >
        {label}
      </span>
      {(() => {
        switch (type) {
          case "date":
            return (
              <div style={{ textAlign: "left", color: "black" }}>
                {value}
              </div>
            )
          case "email":
            return (
              <span style={{ textAlign: textAlign }}>
                <Link
                  href={`mailto:${value}`}
                  /* target="_blank" */ sx={{
                    color: "#241F21",
                    textDecoration: "none",
                    ":visited": {
                      color: "#241F21",
                    },
                  }}
                >
                  {value}
                </Link>
              </span>
            );
          case "phone":
            return (
              <span style={{ textAlign: textAlign }}>
                <Link
                  href={`tel:${value}`}
                  /* target="_blank" */ sx={{
                    color: "#241F21",
                    textDecoration: "none",
                    ":visited": {
                      color: "#241F21",
                    },
                  }}
                >
                  {value}
                </Link>
              </span>
            );
          case "website":
            return (
              <span
                style={{
                  textAlign: textAlign === "right" ? "right" : "left",
                }}
              >
                <Link
                  href={value}
                  target="_blank"
                  sx={{
                    textDecoration: "none",
                    ":visited": {
                      color: "#241F21",
                    },
                  }}
                >
                  {value}
                </Link>
              </span>
            );
          default:
            return (
              <span
                style={{
                  display: maxWidth ? "block" : "flex",
                  textAlign: textAlign,
                  fontSize: multiline ? '12px' : '16px',
                  fontWeight: "600",
                  lineHeight: "20px",
                  padding: "4px 0",
                  wordBreak: wordBreak,
                  maxWidth: maxWidth ? maxWidth : "unset",
                  overflow: maxWidth ? "hidden" : "unset",
                  whiteSpace: maxWidth ? "nowrap" : "primary",
                  textOverflow: maxWidth ? "ellipsis" : "unset"
                }}
                onClick={onClick}
              >
                {value}
              </span>
            );
        }
      })()}
    </div>
  );
};

export default Info;
