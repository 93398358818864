import { Avatar, Box, Menu, MenuItem, Skeleton, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { mobileCheck } from "../../utils/mobileCheck";
import { getEtherFromWei } from "../../utils/unit";
import { SharePriceTooltip } from "../Tooltips";
import ListItem from "./ListItem";

const dashboardTheme = {
  primary: "#FFDB0A",
  secondary: "#3D61B0",
  black: "#241F21",
  black1: "#4B4749",
  fontSize: { xs: "14px", md: "18px" },
  lineHeight: { xs: "17px", md: "180.5%" },
};


function MenuListPercent() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Box
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          p: 0,
          background: 'transparent',
          border: 'none'
        }}
      >
        <Box sx={{ textAlign: "end", bgcolor: '#E6E6E6', p: 1 }}>
          <Typography
            sx={{
              fontSize: "12px",
              lineHeight: "15px",
              fontWeight: "500",
              color: dashboardTheme.black,
              opacity: "0.5",
            }}
          >
            %24h
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              lineHeight: "17px",
              fontWeight: "700",
              pt: "12px",
              // cursor: "pointer",
              color: "#5CDD5C",
            }}
          >
            100%
          </Typography>
        </Box>
      </Box>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem>%24h</MenuItem>
        <MenuItem>%Total</MenuItem>
        <MenuItem>%USD 24h</MenuItem>
        <MenuItem>%USD Total</MenuItem>
      </Menu>
    </div>
  );
}


const ShareItem = ({ openModalShare, view, share, activity,proposal, origLocation, viewOnly }) => {
  const [imageLoaded, setImageLoaded] = React.useState(false);
  const navigation = useNavigate();
  /*const viewHandle = (wepID) => async () => {
    await view(wepID);
    navigation(`/wepID/dashboard`, {
      state: { wepID, weBack: origLocation },
    });
  };*/
  const viewHandle = (wepID) => async () => {
    if(viewOnly) {
      return
    }

    if (activity != null) {
      await view(activity.walletAddr);
      return navigation(`/wepID/dashboard`, {
        state: { wepID: activity.walletAddr, weBack: origLocation },
      });
    }
    await view(wepID);
    navigation(`/wepID/dashboard`, {
      state: { wepID, weBack: origLocation },
    });
  };

  return (
    <ListItem onClick={viewHandle(share != null ? share.walletAddr : proposal?.walletAddr)} sx={{ width: "100%" }}>
      <Avatar
        sx={{
          display: !imageLoaded ? "none" : "inline",
          width: "72px",
          height: "72px",
        }}>
        {share.img !== "" && share.img !== undefined && (
          <Box
            component="img"
            src={`${share.img}`}
            alt=""
            sx={{
              width: "100%",
              mr: imageLoaded ? "12px" : "0px",
            }}
            onLoad={(e) => {
              setImageLoaded(true);
            }}
          />
        )}
      </Avatar>
      {!imageLoaded && (
        <Skeleton
          variant="circular"
          width={72}
          height={72}
          sx={{
            width: mobileCheck() ? "72px" : "80px",
            mr: "12px",
          }}
        />
      )}
      <Box sx={{ width: "calc(100% - 100px)" }}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "20px",
            color: "#193065",
            mb: "6px",
          }}
        >
          {share.name}
        </Typography>
        <Box
          sx={{
            display: "flex",
            // justifyContent: "space-between"
            gap: 2
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: mobileCheck() ? "14px" : "18px",
                fontWeight: "500",
                lineHeight: "17px",
                color: "#A5A5A5",
                mb: mobileCheck() ? "6px" : "12px",
              }}
            >
              My Shares
            </Typography>
            <Typography
              sx={{
                fontSize: mobileCheck() ? "16px" : "20px",
                lineHeight: "20px",
                fontWeight: "600",
                color: "#5CDD5C",
              }}
            >
              {getEtherFromWei(share.amount)}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: mobileCheck() ? "14px" : "18px",
                fontWeight: "500",
                lineHeight: "17px",
                color: "#A5A5A5",
                mb: mobileCheck() ? "6px" : "12px",
              }}
            >
              Price Per Share
            </Typography>
            <SharePriceTooltip>
              <Typography
                sx={{
                  fontSize: mobileCheck() ? "16px" : "20px",
                  lineHeight: "20px",
                  fontWeight: "600",
                  color: "#5CDD5C",
                }}
              >
                ${share.price}
              </Typography>
            </SharePriceTooltip>
          </Box>
          {/* <Box sx={{
            display: "flex",
            ml: 1,
            gap: "6px"
          }}>
            <MenuListPercent />
            <Box sx={{borderRadius: "2px", bgcolor: "#E6E6E6", width: "52px", height: "100%", display: "flex", flexDirection: "column-reverse"}}>
              <Box sx={{bgcolor: "#5CDD5C", height: "50%"}}></Box>
            </Box>
          </Box> */}
        </Box>
      </Box>
    </ListItem>
  );
};

export default ShareItem;
