import { styled, Tooltip as MuiToolTip, tooltipClasses } from "@mui/material";
import React from "react";

const Tooltip = styled(({ className, ...props }) => (
  <MuiToolTip {...props} classes={{ popper: className }} enterTouchDelay={0} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

export default Tooltip;
