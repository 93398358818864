import { Button, CircularProgress, Box } from "@mui/material";
import { makeStyles  } from "@mui/styles";
import React from "react";

const useStyles = makeStyles(theme => ({
	root: {
	  "&.MuiButton-root.Mui-disabled": {
		color: "#ffffff",
	  }
	}
  }));

const CustomButton = ({
	isLoading,
	onClick,
	text,
	fontSize,
	fontFamily,
	backgroundColor,
	hoverColor,
	leftIcon,
	rightIcon,
	height,
	width,
	maxWidth,
	color,
	weight,
	disabled,
	border,
	borderRadius,
	sx,
	containerStyle,
	forStyle,
	forStyle2
}) => {
    const textColor = color ? color : "#ffffff";
    const fontWeight = weight ? weight : "600";
	const classes = useStyles();

	return (
		<Box sx={{
			width: width ? width : "100%",
			maxWidth: maxWidth ? maxWidth : "370px",
			mx: "auto",
			...containerStyle
		}}>
			<Button
				onClick={onClick}
				sx={{
					border: border? border : "none",
					borderRadius: borderRadius? borderRadius: '50px',
					width: width ? width : "100%",
					maxWidth: {sm:maxWidth ? maxWidth : "370px",xs: "320px"},
					height: height ? height : "auto",
					backgroundColor: backgroundColor,
					color: textColor,
					fontSize: forStyle ? {sm:fontSize, xs:"12px"} : forStyle2 ? {sm:fontSize,xs:"11px"} : "14px",
					fontFamily: fontFamily ? fontFamily : "Montserrat",
					fontWeight: fontWeight,
					lineHeight: "17px",
					'&:hover': {
						backgroundColor: hoverColor? hoverColor : "#c7ac14",
					},
					...sx
				}}
				className={classes.root}
				disabled={disabled || isLoading}
				startIcon={leftIcon}
				endIcon={rightIcon}
				variant="filled"
			>
				{isLoading? "Please Wait..." : text}
				{isLoading && (
					<CircularProgress size={20} style={{ marginLeft: 10 }} />
				)}
			</Button>
		</Box>
	)
}

export default CustomButton;
