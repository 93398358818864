import {
  Menu,
  MenuItem
} from "@mui/material";
import React from "react";
import store from "../../constants/store";
import {
  STORE_KEYS
} from "../../constants/store/constant";
import { useAutoConnect } from "../../utils/AutoConnect";

const MainMenu = ({ anchorEl, handleClose, handleNav }) => {
  // const { account } = useAutoConnect()
  const [enterprises, ,] = store.useState(
    STORE_KEYS.id.enterprises
  );

  return (
    <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem onClick={handleNav("home")}>Home</MenuItem>
      <MenuItem onClick={handleNav("forum")}>Forum</MenuItem>
      <MenuItem onClick={handleNav("about")}>About us</MenuItem>
      <MenuItem onClick={handleNav("faq")}>FAQ</MenuItem>
    </Menu>
  )
}

export default MainMenu