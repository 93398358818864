import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Slider,
  IconButton
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { PropagateLoader } from "react-spinners";
import { uploadFile } from "../../utils/couldfront";
import { simpleErrorMessage } from "../../utils/errorMessage";
import { ERRORS } from "../../utils/errors";
import { useNotification } from "../Notification/hook";
import CloseIcon from '@mui/icons-material/Close';
const PhotoEditorModal = ({ open, onClose, image, onOK }) => {
  const editorRef = useRef(null);
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
  const { displaySuccess, displayError } = useNotification();
  const [loading, setLoading] = useState(false);

  const scaleHandler = (event, value, activeThumb) => {
    if (typeof value == "number") {
      setScale(value);
    }
  };

  const positionChangeHandler = (position) => {
    setPosition(position);
  };

  const okHandler = async () => {
    setLoading(true)
    const canvas = editorRef.current?.getImage().toDataURL();
    if (canvas) {
      await fetch(canvas)
        .then((res) => res.blob())
        .then(async (blob) => {
          // const imageURL = window.URL.createObjectURL(blob);
          // onOK(imageURL);
          // return onClose();
          setLoading(true);
          try {
            displaySuccess({
              message: "Uploading image to IPFS server",
              timeout: 5000,
            });
            const ipfsUrl = await uploadFile(blob)
            // const ipfsUrl = await uploadToIPFS(blob, (prog) => {
            //   console.log(`recieved: ${prog}`);
            // });
            onOK(ipfsUrl, window.URL.createObjectURL(blob));
            setLoading(false);
            onClose();
            displaySuccess({
              message: "Upload image to IPFS successfully",
              timeout: 5000,
            });
            setLoading(false);
          } catch (e) {
            setLoading(false);
            console.error("ipfs upload error", e);
            setLoading(false);
            onClose();
            displayError({
              message: ERRORS.UPLOAD_IMAGE,
              reason: simpleErrorMessage(e.message),
              timeout: 5000,
            });
          }
        });
    } else {
      setLoading(false);
      onClose();
    }
  };

  useEffect(() => {
    setScale(1);
    setPosition({ x: 0.5, y: 0.5 });
  }, [image]);
  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogContent sx={{ marginTop: "33px" }}>
        {image && (
          <AvatarEditor
            ref={editorRef}
            image={image}
            borderRadius={100}
            color={[100, 100, 100, 0.7]}
            scale={scale}
            position={position}
            onPositionChange={positionChangeHandler}
          />
        )}
        <Slider
          size="small"
          value={scale}
          valueLabelDisplay="off"
          min={1}
          max={2}
          onChange={scaleHandler}
          step={0.01}
          sx={{
            color: "#000",
          }}
        />
      </DialogContent>
      {loading ? (
        <PropagateLoader
          color="#FFDB0A"
          loading
          style={{
            display: "block",
            height: "10px",
            width: "14px",
            margin: "auto auto 50px",
            paddingLeft: "-20px",
          }}
        />
      ) : (
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button
            onClick={okHandler}
            sx={{
              mx: "auto",
              width: "100%",
              backgroundColor: "#28282B",
              borderRadius: "50px",
              '&:hover': { background: "#28282B" }
            }}
          >
            OK
          </Button>
        </DialogActions>
      )}
      <IconButton onClick={onClose} sx={{ position: "absolute", top: "9px", right: "12px", '&:hover': { background: "none" } }}>
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
};

export default PhotoEditorModal;
