import React, {useEffect, useState} from "react";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export const InfoInput = ({
  label,
  type = "default",
  value,
  textAlign = "right",
  multiline = false,
  lineNum = 1,
  placeholder,
  onChange,
  inputMode="text"
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [align, setAlign] = useState(textAlign);

  // Update the textAlign when mobile
  useEffect(() => {
    if (isMobile) {
      setAlign("left");
    }
  }, []);

  return (
    <div
      style={{
        display: multiline || isMobile ? "block" : "flex",
      }}
    >
      <span
        style={{
          textAlign: "left",
          width: "100%",
          color: theme.palette.text.secondary,
        }}
      >
        {label}
      </span>
      {!multiline ? (
        <input
          className="infoInput"
          value={value}
          onChange={(eve) => {
            onChange(eve);
          }}
          inputMode={inputMode}
          placeholder={placeholder}
          style={{
            color: theme.palette.text.primary,
            textAlign: align,
            width: "calc(100% - 32px)",
            padding: "16px",
            fontSize: '20px',
            fontWeight: "500",
            lineHeight: "24px"
          }}
        />
      ) : (
        <textarea
          className="infoInput"  
          value={value}
          onChange={(eve) => {
            onChange(eve);
          }}
          inputMode={inputMode}
          placeholder={placeholder}
          rows={lineNum}
          style={{
            color: theme.palette.text.primary,
            width: "100%",
            resize: "none",
            textAlign: align,
            padding: "16px",
            fontSize: '20px',
            fontWeight: "500",
            lineHeight: "24px"
          }}
        />
       
      )}
    </div>
  );
};

export default InfoInput;
