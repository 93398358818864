import React from 'react';
import Chart from 'react-apexcharts';

const DonutChart = (props) => {
  const { chartSeries, chartOptions, width, height, colors, ...other } = props;
	const optionDonut = {
		chart: {
				type: 'donut',
		},
		dataLabels: {
			enabled: false,
		},
		plotOptions: {
			pie: {
				customScale: 1,
				expandOnClick: false,
				donut: {
					size: '80%',
				},
			}
		},
		tooltip: {
			enabled: false
		},
		legend: {
			show: false
		},
		colors: colors,
		states: {

		}
	}

  return (
		<Chart
			width={width}
			height={height}
			options={{...chartOptions, ...optionDonut}}
			series={chartSeries}
			type="donut"
		/>
  );
};

export default DonutChart;