import React from "react";
import { Box, Button, Divider, Grid, IconButton, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from "react-router-dom";
import { AppBar, CreateEnterpriseAppBar, CreateEnterpriseToolBar, HomeAppBar } from "../AppBar";
import ShareholdersList from "./ShareholdersList";

import store from "../../constants/store";
import { STEP, STORE_KEYS } from "../../constants/store/constant";
import { useTheme } from '@mui/material/styles';
import {WEButton} from "../Button";
import { upsertDraft } from "../../apis";
import { useAutoConnect } from "../../utils/AutoConnect";

const ShareholdersView = () => {
  const theme = useTheme();
  const navigation = useNavigate();
  const { account } = useAutoConnect();
  const [enterprises, ,] = store.useState(STORE_KEYS.id.enterprises);
  const shareholdersState = enterprises.tempEnterprise.shareholders;
  const draftId = enterprises.tempEnterprise.draftId;
  const [stepState, setStep] = store.useState(STORE_KEYS.id.step);

  const backHandler = () => {
    if (stepState === STEP.CREATE_SHAREHOLDERS_VIEW) {
      setStep(STEP.CREATE_ENTERPRISE_INPUT);
    } else {
      navigation(-1);
    }
  };

  const addHandler = () => {
    if (stepState === STEP.CREATE_SHAREHOLDERS_VIEW) {
      setStep(STEP.CREATE_SHAREHOLDER_ADD);
    } else {
      setStep(STEP.SHAREHOLDER_ADD);
    }
  };

  const continueHandler = async () => {
    try {
      await upsertDraft(draftId, { admin_address: account, status: "PROCESSING", shareholders: JSON.stringify(shareholdersState) });
      setStep(STEP.CREATE_ADMINS_VIEW);
    } catch (error) {
      console.log('Draft Save ERROR')
      setStep(STEP.CREATE_ADMINS_VIEW);
    }
  };

  return (
    <>
      <Grid
        container
        component="main"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          px: 3,
        }}
      >
        {/* <CreateEnterpriseToolBar
          title="Shareholders"
          back={backHandler}
          type={shareholdersState.length === 0 ? "none" : "add"}
          handle={shareholdersState.length === 0 ? undefined : addHandler}

        /> */}
        <Divider flexItem />
        {shareholdersState.length === 0 ? (
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              // justifyContent: "center",
              alignItems: "center",
              m: 0,
              p: 0,
            }}
          >
            <Box
              component="img"
              src="/images/shareholders.svg"
              alt={"splash"}
              sx={{
                right: "0",
                my: 5,
                width: "min(28.23vh, 53.99vw)",
                height: "min(26.06vh, 49.83vw)",
              }}
            />
            {/* <Typography variant="h2" sx={{ mt: "min(6.63vh, 12.67vw)" }}>
              Add Shareholders
            </Typography> */}
            <Typography
              variant="body2"
              sx={{
                textAlign: "center",
                maxWidth: "800px",
              }}
            >
              Shareholders own equity in the World Enterprise and vote on proposals for the World Enterprise. Each share is equal to 1 vote. Users can become shareholders by requesting to join or by purchasing shares.
            </Typography>
            <Box
              sx={{
                marginTop: 5,
                mx: "auto",
                width: { xs: "100%", sm: "370px" },
                pb: 4,
                textAlign: 'center'
              }}
            >
              <WEButton
                type='primary'
                rounded='xl'
                onClick={addHandler}
              >
                  Add Shareholders
              </WEButton>
            </Box>
          </Container>
        ) : (
          <>
            <ShareholdersList />
            {stepState === STEP.CREATE_SHAREHOLDERS_VIEW && (
              <Box
                sx={{
                  marginTop: 5,
                  mx: "auto",
                  width: { xs: "100%", sm: "370px" },
                  pb: 4,
                  textAlign: 'center'
                }}
              >
                <WEButton
                  type='primary'
                  rounded='xl'
                  width={260}
                  onClick={continueHandler}
                >
                  Continue
                </WEButton>
              </Box>
            )}
          </>
        )}
      </Grid>
    </>
  );
};

export default ShareholdersView;
