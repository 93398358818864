import { ContentCopy, Done } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Stack } from "@mui/system";
import copy from "copy-to-clipboard";
import React, { useState } from "react";

const InfoAddr = ({ label, value, addr }) => {
  const [copyed, setCopyed] = useState(false);
  const copyToClipboard = () => {
    try {
      copy(addr);
      setCopyed(true);
    } catch (e) { }
  };

  return (
    <>
      <Stack direction={"row"} gap={2}>
        <span style={{ textAlign: "left", color: "#6F7287" }}>{label}</span>
        <span
          style={{
            textAlign: "left",
            maxWidth: "13ch",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: "#FF6142"
          }}
        >
          {value}
          <IconButton
            onClick={copyToClipboard}
            sx={{
              float: "right",
              width: "19px",
              height: "19px",
              marginLeft: "9px",
              color: "#FF6142"
            }}
          >
            {!copyed ? (
              <ContentCopy sx={{ width: "19px", height: "19px" }} />
            ) : (
              <Done sx={{ width: "19px", height: "19px" }} htmlColor="#FF6142" />
            )}
          </IconButton>
        </span>
      </Stack>
    </>
  );
};

export default InfoAddr;
