import { Link } from "@mui/material";
import React from "react";

const landingTheme = {
  primary: "#FFDB0A",
  secondary: "#3D61B0",
  black: "#0D141E",
  fontSize: { xs: "14px", md: "18px" },
  lineHeight: { xs: "17px", md: "180.5%" },
};

const NavLink = ({ page, onClick }) => {
  return (
    <Link
      underline="none"
      key={page}
      sx={{
        my: 2,
        color: `${landingTheme.black} !important`,
        lineHeight: {
          md: "28.88px",
        },
        mr: "59px",
        display: "block",
        width: "fit-content",
      }}
      style={{ cursor: "pointer" }}
      onClick={onClick}
    >
      {page}
    </Link>
  );
};

export default NavLink;
