import store from "../../constants/store";
import { STORE_KEYS } from "../../constants/store/constant";

export const useNotification = () => {
  const [, , updateNotification] = store.useState(STORE_KEYS.id.notification);

  const displayNotification = ({ type, message, timeout, detail }) => {
    updateNotification((prev) => {
      prev.open = true;
      prev.type = type;
      prev.message = message;
      prev.timeout = timeout;
      prev.detail = detail;
      return prev;
    });
  };

  const displaySuccess = ({ message, timeout }) => {
    displayNotification({ type: "success", message, timeout });
  };

  const displayError = ({ message, reason="", timeout }) => {
    displayNotification({ type: "error", message: `${message} ${reason}`, timeout });
  };

  const displayWarning = ({ message, timeout }) => {
    displayNotification({ type: "warning", message, timeout });
  };

  const clearNotification = () => {
    updateNotification((prev) => {
      prev.open = false;
      return prev;
    });
  };

  return {
    displaySuccess,
    displayError,
    displayWarning,
    displayNotification,
    clearNotification,
  };
};
