import HelpIcon from '@mui/icons-material/Help';
import { Box, Divider, Grid, IconButton } from '@mui/material';
import { ethers } from 'ethers';
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { CreateEnterpriseToolBar } from '../AppBar';

import { ToolTip } from '../Dialog';
import {
  Input,
  InputLabel,
  PhoneNumInput,
  WalletAddrAutocomplete,
} from '../Input';
import { useWeb3React } from "@web3-react/core";
import store from "../../constants/store";
import { EmptyAdmin, EmptyProposal, EmptyShareholder, ProposalType, STEP, STORE_KEYS } from "../../constants/store/constant";
import { useEnterprise } from "../../hook/weEnterprise";
import { useWeb3 } from '../../hook/web3';
import {WEButton} from "../Button";
import { useNotification } from "../Notification/hook";


function AdminAdd() {
  const theme = useTheme();
  const { account } = useWeb3React();
  const { displayError } = useNotification();
  const [enterprises, , updateEnterprises] = store.useState(
    STORE_KEYS.id.enterprises,
  );
  const [step, setStep] = store.useState(STORE_KEYS.id.step);

  const adminsState = enterprises.tempEnterprise.admins;
  const shareholdersState = enterprises.tempEnterprise.shareholders;
  const toEditState = enterprises.toEditAdmin;
  const enterpriseHandler = useEnterprise(enterprises.tempEnterprise.info.address);
  const web3 = useWeb3();

  const [admin, setAdmin] = useState({
    walletAddr: '',
    isActive: true,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });

  const handleInputChange = (type) => (evt) => {
    const { value } = evt.currentTarget;
    switch (type) {
      case 'walletAddr':
        setAdmin((prev) => ({
          ...prev,
          walletAddr: value,
        }));
        break;
      case 'firstName':
        setAdmin((prev) => ({
          ...prev,
          firstName: value,
        }));
        break;
      case 'lastName':
        setAdmin((prev) => ({
          ...prev,
          lastName: value,
        }));
        break;
      case 'email':
        setAdmin((prev) => ({
          ...prev,
          email: value,
        }));
        break;
      default:
        break;
    }
  };

  const handlePhoneNumInput = (value) => {
    setAdmin((prev) => ({
      ...prev,
      phone: value,
    }));
  };

  const continueHandler = (data) => async () => {
    if (!data?.walletAddr) {
      return displayError({
        message: 'Please input wallet address',
        reason: "",
        timeout: 5000,
      });
    }
    if (!ethers.utils.isAddress(data.walletAddr)) {
      return displayError({
        message: 'Please input correct wallet address',
        reason: "",
        timeout: 5000,
      });
    }

    const proposal = EmptyProposal();
    proposal.id = Date.now();
    proposal.type = ProposalType.ADMIN;
    proposal.walletAddr = data.walletAddr;
    proposal.name = `${data.firstName || ''} ${data.lastName || ''}`.trim();
    const info = EmptyAdmin();
    info.fullName = proposal.name;
    info.isActive = true;
    info.email = data.email;
    info.walletAddr = data.walletAddr;
    info.phone = data.phone;
    switch (step) {
      case STEP.CREATE_ADMIN_ADD:
        updateEnterprises((prev) => {
          prev.tempEnterprise.admins = [
            ...prev.tempEnterprise.admins,
            {
              ...info,
              isActive: true,
            },
          ];
          return prev;
        });
        setStep(STEP.CREATE_ADMINS_VIEW);
        break;
      case STEP.CREATE_ADMIN_EDIT:
        updateEnterprises((prev) => {
          prev.tempEnterprise.admins[prev.toEditAdmin] = {
            ...info,
            isActive: true,
          };
          return prev;
        });
        setStep(STEP.CREATE_ADMINS_VIEW);
        break;
      case STEP.ADMIN_ADD:
      case STEP.PROPOSAL_ADMIN:
        updateEnterprises((prev) => {
          prev.tempEnterprise.admins = [
            ...prev.tempEnterprise.admins,
            {
              ...info,
              isActive: false,
            },
          ];
          prev.tempEnterprise.proposals = [
            ...prev.tempEnterprise.proposals,
            proposal,
          ];

          return prev;
        });
        setStep(STEP.INDEX);
        break;
      case STEP.ADMIN_EDIT:
      case STEP.DASHBOARD_ADMIN_EDIT:
        try {
          await enterpriseHandler.methods
            .transferOwnership(info.walletAddr)
            .send({ from: account, gasLimit: web3.eth.getBlock("latest").gasLimit });
        } catch (error) {
          console.log(`Error ${error}`);
          break;
        }

        updateEnterprises((prev) => {
          prev.tempEnterprise.admins[prev.toEditAdmin] = {
            ...info,
            isActive: true,
          };
          // prev.tempEnterprise.proposals = [
          //   ...prev.tempEnterprise.proposals,
          //   {
          //     ...proposal,
          //     type: "adminReplace",
          //   },
          // ];
          // return prev;
        });
        setStep(STEP.INDEX);
        break;
      case STEP.DASHBOARD_MEMBERS_ADMIN_EDIT:
        updateEnterprises((prev) => {
          // eslint-disable-next-line no-param-reassign
          prev.tempEnterprise.admins[prev.toEditAdmin] = {
            ...info,
            isActive: false,
          };
          prev.tempEnterprise.proposals = [
            ...prev.tempEnterprise.proposals,
            {
              ...proposal,
              type: 'adminReplace',
            },
          ];
          return prev;
        });
        setStep(STEP.DASHBOARD_MEMBERS);
        break;
      default:
        break;
    }
  };

  const backHandler = () => {
    switch (step) {
      case STEP.CREATE_ADMIN_ADD:
      case STEP.CREATE_ADMIN_EDIT:
        setStep(STEP.CREATE_ADMINS_VIEW);
        break;
      case STEP.ADMIN_ADD:
      case STEP.ADMIN_EDIT:
      case STEP.PROPOSAL_ADMIN:
      case STEP.DASHBOARD_ADMIN_EDIT:
        setStep(STEP.INDEX);
        break;
      case STEP.DASHBOARD_MEMBERS_ADMIN_EDIT:
        setStep(STEP.DASHBOARD_MEMBERS);
        break;
      default:
        break;
    }
  };

  const renderBtnLabel = (state) => {
    switch(state) {
      case STEP.CREATE_ADMIN_ADD:
      case STEP.ADMIN_ADD:
        return 'Add Admin';
      case STEP.PROPOSAL_ADMIN:
        return 'Propose Admin';
      case STEP.CREATE_ADMIN_EDIT:
      case STEP.ADMIN_EDIT:
      case STEP.DASHBOARD_ADMIN_EDIT:
        return 'Replace Admin';
      case STEP.DASHBOARD_MEMBERS_ADMIN_EDIT:
        return 'Propose Admin';
    }
  }

  useEffect(() => {
    switch (step) {
      case STEP.CREATE_ADMIN_EDIT:
      case STEP.ADMIN_EDIT:
      case STEP.DASHBOARD_ADMIN_EDIT:
        const original = adminsState.at(toEditState);
        if (original) {
          setAdmin(original);
        }
        break;
      default:
        break;
    }
  }, [adminsState, setAdmin, step, toEditState]);

  return (
    <>
      <Grid
        container
        component="main"
        spacing={2}
        direction="row"
        sx={{
          px: 3,
          pt: 0,
        }}
      >
        {/* <CreateEnterpriseToolBar
          position="sticky"
          title={(() => {
            switch (step) {
              case STEP.CREATE_ADMIN_ADD:
              case STEP.ADMIN_ADD:
                return 'Add Admin';
              case STEP.PROPOSAL_ADMIN:
                return 'Propose Admin';
              case STEP.CREATE_ADMIN_EDIT:
              case STEP.ADMIN_EDIT:
              case STEP.DASHBOARD_ADMIN_EDIT:
                return 'Replace Admin';
              case STEP.DASHBOARD_MEMBERS_ADMIN_EDIT:
                return 'Propose Admin ';
              default:
                return '';
            }
          })()}
          back={backHandler}
          handle={() => {}}
          type="none"
          toolTipTitle="Add new admin"
        /> */}
        <Divider flexItem />
        <Grid item xs={12}>
          <InputLabel shrink htmlFor="walletAddr">
            Wallet Address
            <ToolTip title={'Add the crypto wallet to have Admin permissions'}>
              <IconButton size="small" color="primary">
                <HelpIcon fontSize="small" color="primary" />
              </IconButton>
            </ToolTip>
          </InputLabel>
          <WalletAddrAutocomplete
            value={admin.walletAddr}
            options={(() => {
              let newSh = EmptyShareholder();
              newSh.walletAddr = account || '';
              let name = (enterprises.name || '').split(' ');
              newSh.firstName = name[0] || '';
              newSh.lastName = name[1] || '';
              let idx = shareholdersState.findIndex(
                (ele) => ele.walletAddr === newSh.walletAddr
              );
              if (idx < 0) {
                return [newSh, ...shareholdersState];
              }
              return shareholdersState;
            })().map((shareholder) => shareholder)}
            onChange={(event, newValue) => {
              if (newValue.walletAddr) {
                setAdmin((prev) => {
                  return {
                    walletAddr: newValue.walletAddr,
                    firstName: newValue.firstName || '',
                    lastName: newValue.lastName || '',
                    isActive: prev.isActive,
                    email: '',
                    phone: '',
                  };
                });
              }
            }}
            onAddrChange={(value) => {
              setAdmin((prev) => {
                return {
                  ...prev,
                  walletAddr: value,
                };
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} mt={2}>
          <InputLabel shrink htmlFor="firstname">
            First Name (optional)
          </InputLabel>
          <Input
            id="firstName"
            value={admin.firstName}
            fullWidth
            sx={{ mt: 1 }}
            onChange={handleInputChange('firstName')}
          />
        </Grid>
        <Grid item xs={12} md={6} mt={2}>
          <InputLabel shrink htmlFor="lastName">
            Last Name (optional)
          </InputLabel>
          <Input
            id="lastName"
            value={admin.lastName}
            fullWidth
            sx={{ mt: 1 }}
            onChange={handleInputChange('lastName')}
          />
        </Grid>
        <Grid item xs={12} mt={2}>
          <InputLabel shrink htmlFor="email">
            Email (optional)
          </InputLabel>
          <Input
            id="email"
            value={admin.email}
            fullWidth
            sx={{ mt: 1 }}
            onChange={handleInputChange('email')}
          />
        </Grid>
        <Grid item xs={12} mt={2}>
          <InputLabel shrink htmlFor="phone">
            Phone (optional)
          </InputLabel>
          <Box sx={{ p: 0, mt: 1 }}>
            <PhoneNumInput value={admin.phone} onChange={handlePhoneNumInput} />
          </Box>
        </Grid>
        <Grid item xs={12} display="flex">
          <Box
            sx={{
              marginTop: 5,
              mx: 'auto',
              width: { xs: '100%', sm: '370px' },
              pb: 4,
              textAlign: 'center'
            }}
          >
            <WEButton
              type='primary'
              rounded='xl'
              width={280}
              onClick={continueHandler(admin)}
            >
              {renderBtnLabel(step)}
            </WEButton>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default AdminAdd;
