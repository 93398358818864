import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";

const Index = ({ title, children }) => {
  const [opened, setOpened] = React.useState(false);

  const openHandler = () => {
    setOpened(!opened);
  };

  return (
    <Container onClick={openHandler}
      sx={{
        borderWidth: "3px",
        borderColor: "black",
        borderRadius: "8px",
        backgroundColor: "#FAFBFC",
        px: 2,
        py: 1,
        width: "80%",
      }}
    >
      <Box
        component="div"
        sx={{
          mr: -1,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            textAlign: "left",
            fontSize: "15px",
          }}
        >
          {title}
        </Typography>
        <div>
          <IconButton>
            {!opened ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
          </IconButton>
        </div>
      </Box>
      {opened && (
        <Box component="div" mt={1}>
          {children}
        </Box>
      )}
    </Container>
  );
};

export default Index;
