import { Close } from "@mui/icons-material";
import { DialogContent, DialogTitle, Divider, IconButton } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
// import store from "../../constants/store";
// import { STORE_KEYS } from "../../constants/store/constant";
import { shortAddress } from "../../utils/shortAddress";
import { HomeAppBar, ModalHead } from "../AppBar";
import DetailDialog, { WEModal } from "./DetailDialog";
import Info from "./Info";
import InfoAddr from "./InfoAddr";
import Transition from "./Transition";

const TransactionDetailModal = ({
  open,
  handleClose,
  value,
  type,

}) => {
  // const [enterprises] = store.useState(STORE_KEYS.id.enterprises);
  return (
    <WEModal
      open={open}
      category="transactionDetail"
      onClose={() => handleClose()}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle sx={{ p: "0 !important" }} id="alert-dialog-title">
        <ModalHead
          title={"Transaction Detail"}
          close={() => handleClose()}
          customClose={() => handleClose()}
          color='#FFDB0B'
        />
      </DialogTitle>
      <DialogContent sx={{ padding: "0 16px !important" }}>
        {/* To and From  */}
        <Stack direction={"column"} gap={4} >
          <Info
            label="Date"
            type="date"
            labelColor="grayLabel"
            multiline
            value={
              (new Date(value?.created_at)).toLocaleString("en-us", {
                day: "numeric",
                month: "short",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
              }) || ""
            }
          />
          <Info
            label="Category"
            multiline
            labelColor="grayLabel"
            value={`${value?.category}`}
          />
          <Info
            label="Type"
            labelColor="grayLabel"
            multiline
            value={`${type}`}
          />
          <Info
            label="Asset"
            labelColor="grayLabel"
            multiline
            value={`${value?.asset
              }`}
          />
          <Info label="Amount" multiline labelColor="grayLabel" value={`${value?.amount.toString()}` || ""} />
          <Info label="Note" multiline labelColor="grayLabel" value={value?.note || ""} />
          <InfoAddr label="To" labelColor="grayLabel" multiline value={shortAddress(value?.to)} addr={value?.to} />
          <InfoAddr label="From" labelColor="grayLabel" multiline value={shortAddress(value?.from)} addr={value?.from} />
        </Stack>

      </DialogContent>
    </WEModal >
  );
};

export default TransactionDetailModal;
