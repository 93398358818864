import { Close, HelpOutlineOutlined } from "@mui/icons-material";
import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import React, {useState, useEffect} from "react";

const CreateAppBar = ({ title, close, helpEnabled, bgColor = "#FFFFFF" }) => {


  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: bgColor,
        boxShadow: "none",
        borderBottom: "2px solid #E3E8EB",
      }}
    >
      <Toolbar sx={{ px: 3, marginTop:{xs:0}}}>
        <Typography
          variant="h2"
          component="div"
          sx={{
            flexGrow: 1,
            pr: {sm:!helpEnabled ? "36px!important" : "0px", xs:"0px"},
            fontSize:{
              xs:"15px",
              sm:"20px"
            }
          }}
        >
          {title}
        </Typography>
        {helpEnabled && (
          <IconButton>
            <HelpOutlineOutlined />
          </IconButton>
        )}

        <IconButton
          size="large"
          edge="end"
          color="primary"
          aria-label="menu"
          onClick={() => {
            close();
          }}
        >
          <Close fontSize="medium" htmlColor="#4B4749" />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default CreateAppBar;
