import { Select as MuiSelect, styled } from "@mui/material";
import "react-phone-input-2/lib/style.css";

const Select = styled(MuiSelect)(({ theme }) => ({
  borderRadius: "8px",
  backgroundColor: "#FAFBFC",
  color: "#4B4749",
  fontSize: "15px",
  fontWeight: 500,
  fontStyle: "normal",
  fontFamily: "Montserrat",
  borderColor: "#aaa",

  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#aaa !important",
  },
  "& .MuiOutlinedInput-input": {
    padding: "12px",
    borderRadius: "8px",
  },
  "& Mui-disabled ~ .MuiOutlinedInput-notchedOutline": {
    borderColor: "#aaa",
  },
  "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled":
    {
      // "-webkit-text-fill-color": "#4B4749",
      WebkitTextFillColor: "#4B4749",
      backgroundColor: "#FAFBFC",
      borderColor: "#aaa",
      borderRadius: "8px",
    },
  // '&$focused .MuiOutlinedInput-notchedOutline': {
  //   boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
  //   border: '1px solid #ced4da',
  // }
}));

export default Select;
