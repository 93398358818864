import { Button, styled } from "@mui/material";

const BtnOption = styled(Button)(({ theme }) => ({
  border: "none",
  height: "48px",
  margin: "5px 0px",
  padding: "15px",
  backgroundColor: "#FFFFFF",
  textAlign: "left",
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "18px",
  lineHeight: "22px",
  color: "#367BCF",
  width: "100%",
  boxShadow: "none",
  display: "flex",
  justifyContent: "space-between",
  ":hover": {
    backgroundColor: "#EEEEEE",
    boxShadow: "none",
  },
  "& span": {
    width: "100%",
  },
}));

export default BtnOption;
