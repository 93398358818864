import { Box, Link, Typography } from '@mui/material';
import React from 'react';
import EditIcon from "@mui/icons-material/Edit";
import { AddressInput, Input, InputLabel } from "../Input";
import PropTypes from 'prop-types'


const EditableInput = props => {
  const { type, editable, label, value, onEdit, field, required=false, handleChange } = props
  return(
    <Box
      width="100%"
      mt={3}
      display="flex"
      justifyContent="space-between"
    >
      {!editable ? (
        <>
          <Box>
            <Typography variant="body2">{label}</Typography>
            <Typography
              variant="h3"
              mt={2}
              sx={{
                fontWeight: 500,
                minHeight: "20px",
                maxWidth: "100%",
                wordBreak: "break-all",
              }}
            >
              {value}
            </Typography>
          </Box>
          <Box>
            <Link
              onClick={() => onEdit(field)}
              sx={{ display: { xs: "flex", sm: "flex" } }}
            >
              <EditIcon
                  style={{
                    marginLeft: "6px",
                    position: "relative",
                    top: "5px",
                    color: "#6F7287",
                  }}
                />
            </Link>
          </Box>
        </>
      ) : (
        <Box width="100%">
          <InputLabel shrink htmlFor={field} sx={{ color: "#6F7287" }}>
            {label} {!required ? '(optional)' : '' }
          </InputLabel>
          {type === 'address' ? 
            <AddressInput
              address={value}
              onChange={val => handleChange(val, field)}
            />
            :
            <Input
              id={label}
              value={value}
              fullWidth
              sx={{ mt: 1 }}
              onChange={val => handleChange(val, field)}
            />
          }
          
        </Box>
      )}
    </Box>
  )
}

EditableInput.propTypes = {
  type: PropTypes.string,
  editable: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.any,
  onEdit: PropTypes.func,
  field: PropTypes.string,
  required: PropTypes.bool,
  handleChange: PropTypes.func
}

EditableInput.defaultProps = {
  type: null,
  editable: false,
  label: '',
  value: null,
  field: null,
  required: false,
}

export default EditableInput;