import { Close, HelpOutlineOutlined } from "@mui/icons-material";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton
} from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { shortAddress } from "../../utils/shortAddress";
import { ModalHead } from "../AppBar";
import BtnCancel from "./BtnCancel";
import DetailDialog, { WEModal } from "./DetailDialog";
import Info from "./Info";
import InfoAddr from "./InfoAddr";
import ToolTip from "./ToolTip";
import Transition from "./Transition";

const AdminDetailModal = ({
  open,
  onClose,
  isAdmin,
  email,
  phone,
  walletAddr,
  replace,
  remove,
}) => {
  // const emailHandle = (emailAddr) => () => {};

  // const phoneHandle = (phoneNumAddr) => () => {};
  return (
    <WEModal
      open={open}
      onClose={() => onClose()}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle sx={{ p: "0px !important" }} id="alert-dialog-title">
        <ModalHead
          title={"Admin"}
          customClose={() => onClose()}
          color='#FFDB0B'
          theme="light"
          helpIcon
        />
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ p: "0 16px !important" }}>
        <Stack direction={"column"} gap={7}>
          <Info label="Email" labelColor="grayLabel" value={email} type="email" />
          <Info label="Phone" labelColor="grayLabel" value={phone} type="phone" />
          <InfoAddr label="Wallet" labelColor="grayLabel" value={shortAddress(walletAddr)} />

        </Stack>
        {
          !isAdmin &&
          <DialogActions>
            <BtnCancel
              sx={{
                backgroundColor: "white",
                width: "100%",
                borderRadius: "24px",
                border: "1px solid #28282B"
              }}
              onClick={() => replace()}
            >
              Replace
            </BtnCancel>
            {/* <BtnCancel onClick={() => remove()}>Remove</BtnCancel> */}
          </DialogActions>
        }
      </DialogContent>
    </WEModal >
  );
};

export default AdminDetailModal;
