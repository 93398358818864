import React from "react";
import { getEnterprises } from "../apis";
import store from "../constants/store";
import { EmptyAdmin, EmptyEnterprise, EmptyShare, EmptyShareholder, STORE_KEYS } from "../constants/store/constant";
import { useAutoConnect } from "../utils/AutoConnect";
import { getIpfsData } from "../utils/ipfs";
import { convertMaticToUSD, getEtherFromWei } from "../utils/unit";

export const loadEnterprisesUserView = (account, accountShares) => getEnterprises().then((res) => {
  let result = [];
  const moreLoaders = []
  try {
    const enterprises = res.data?.exists && res.data.data ? res.data.data : []
    for (let i = 0; i < enterprises.length; i++) {
      moreLoaders.push(getIpfsData(enterprises[i].metadata)
        .then((ipfs_res) => ipfs_res.data)
        .then((data) => {
          // Additional logic to extract description/background Url from metadata
          result[i].info.backgroundUrl = data.info.backgroundUrl;
          result[i].info.description = data.info.description;
          // } else {
          //   const data = ipfs_res.data;

          //   result[i].admins = data.admins;
          //   result[i].businessUpdates = data.businessUpdates;

          //   result[i].info.description = data.info.description;
          //   result[i].info.isRegisterd = data.info.isRegisterd;
          //   result[i].info.logo = data.info.logo;
          //   result[i].info.memNum = data.info.memNum;
          //   result[i].info.name = data.info.name;
          //   result[i].info.website = data.info.website;

          //   if (result[i].admins[0].walletAddr.toUpperCase() === account.toUpperCase()) {
          //     result[i].mine = true;
          //   }
          //   result[i].orders = data.orders;
          //   result[i].proposals = data.proposals;
          //   result[i].shareholders = data.shareholders;
          //   result[i].transactions = data.transactions;
          // }
        }).catch((error) => console.log('getIpfsData error', enterprises[i], error))
      )
      result[i] = EmptyEnterprise()
      result[i].info.address = enterprises[i].enterprise_address
      result[i].info.wepID = enterprises[i].created_at
      result[i].info.type = enterprises[i].type
      result[i].info.metaUrl = enterprises[i].metadata
      result[i].info.tokenName = enterprises[i].token_symbol
      result[i].info.created_at = Number(enterprises[i].created_at)

      // Commented out decision logic, used description and background url from metadataUri
      // if (ipfs_res === null) {
      result[i].info.name = enterprises[i].token_name;
      const admin = EmptyAdmin()
      admin.walletAddr = enterprises[i].admin_address
      result[i].admins = [admin];
      result[i].info.logo = enterprises[i].logoImg;
      if (parseInt(enterprises[i].price) > 0) {
        const _maticPrice = getEtherFromWei(enterprises[i].price);
        moreLoaders.push(convertMaticToUSD(_maticPrice)
          .then((_price) => {
            _price = _price.toFixed(4);
            result[i].info.price = Number(_price);
          })
        )
      }

      let shareholders = JSON.parse(enterprises[i].shareholders)
      let sh_addresses = Object.keys(shareholders)
      // eslint-no-loop-func
      let totalShare = 0;
      sh_addresses.forEach((address) => { // eslint-disable-line no-loop-func
        let tmp = EmptyShareholder()
        tmp.walletAddr = address;
        tmp.numOfShare = shareholders[address]
        totalShare = totalShare + getEtherFromWei(Number(shareholders[address]))
        result[i].shareholders = [
          ...result[i].shareholders,
          tmp
        ]
      })
      result[i].info.totalShare = totalShare
      result[i].info.memNum = shareholders ? Object.keys(shareholders).length : 0
    }
  } catch (e) { }
  result = [
    // enterpriseInitialState.enterprises[0],
    ...result
  ]
  let temp = []
  const _shareListKeys = Object.keys(accountShares);
  for (let i = 0; i < _shareListKeys.length; i++) {
    let exist = false;
    const addr = _shareListKeys[i]
    const share = accountShares[addr]
    result.forEach((ele, idx, origin) => {
      if (ele.info.address === addr) {
        exist = true
        result[idx].isShareholder = true
        result[idx].numOfShare = share.amount
        const myIdx = ele.shareholders.findIndex((shareholder) => {
          return shareholder.walletAddr.toUpperCase() === account
        })
      }
    })
    if (!exist) {
      let newWE = EmptyEnterprise()
      newWE.isShareholder = true
      newWE.info.address = _shareListKeys[i]
      newWE.info.metaUrl = share.ipfs
      newWE.info.logo = share.logo
      newWE.info.name = share.name
      newWE.info.numOfShare = share.amount
      let tes = EmptyShareholder()
      tes.walletAddr = account
      newWE.shareholders = [tes]
      temp = [
        ...temp,
        newWE
      ]
    }
  }

  return Promise.all(moreLoaders)
    .then(() => {
      return [...result, ...temp]
    })
});

export const filterEnterprisesViewToMyShares = (account, enterprises) => {
  const shs = [];
  for (const enterprise of enterprises.filter((e) => e.isShareholder)) {
    let sh = EmptyShare();
    sh.isShareholder = true;
    sh.img = enterprise.info.logo;
    sh.name = enterprise.info.name;
    sh.price = enterprise.info.price;
    // sh.amount = 0;
    const idx = enterprise.shareholders.findIndex((shareholder) => {
      return (
        (shareholder.walletAddr || '').toUpperCase() ===
        (account || '').toUpperCase()
      );
    });
    if (idx >= 0) {
      sh.amount = enterprise.shareholders[idx].numOfShare;
    }
    sh.tokenName = enterprise.info.tokenName;
    sh.wepID = enterprise.info.wepID;
    sh.mine = enterprise.mine;
    sh.walletAddr = enterprise.info.address;
    shs.push(sh)
  }
  return shs
}

export function useEnterpriseServer() {
  const [enterprises, , updateEnterprises] = store.useState(
    STORE_KEYS.id.enterprises
  );
  const { account, accountInfo } = useAutoConnect();
  React.useEffect(() => {
    if (!account) return;

    updateEnterprises((prev) => {
      prev.isLoading = true;
    });
    let accountShares = {};
    if (accountInfo?.exists && accountInfo.enterprises) {
      accountShares = JSON.parse(accountInfo.enterprises)
    }
    loadEnterprisesUserView(account, accountShares)
      .then((res) => updateEnterprises((prev) => {
        prev.isLoading = false;
        prev.enterprises = res;
      }));
  }, [accountInfo]); // eslint-disable-line react-hooks/exhaustive-deps
  return enterprises.enterprises;
}
