import { createStore } from "state-pool";
import { STEP, STORE_KEYS } from "./constant";
import {
  activityInitialState,
  enterpriseInitialState,
  notificationInitialState,
  sharesInitialState
} from "./initialState";

const store = createStore();
store.setState(STORE_KEYS.id.tabValue, enterpriseInitialState.tabValue);
store.setState(STORE_KEYS.id.secondTabValue, enterpriseInitialState.secondTabValue);
store.setState(STORE_KEYS.id.enterprises, enterpriseInitialState);
store.setState(STORE_KEYS.id.notification, notificationInitialState);
store.setState(STORE_KEYS.id.shares, sharesInitialState);
store.setState(STORE_KEYS.id.activities, activityInitialState);
store.setState(STORE_KEYS.id.step, STEP.INDEX);
store.setState(STORE_KEYS.id.sstep, STEP.INDEX);
export default store;
