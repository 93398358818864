import { Avatar, Box, Typography } from "@mui/material";
import { default as React } from "react";

const profileTheme = {
  primary: "#FFDB0A",
  secondary: "#3D61B0",
  black: "#241F21",
  fontSize: { xs: "14px", md: "18px" },
  lineHeight: { xs: "17px", md: "180.5%" },
};

const UserProfileHead = ({ photoUrl, totalShareValue, goChart }) => {
  return (
    <Box
      component={"header"}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#FFDB0A",
        pt: 10,
        pb: 6,
      }}
    >
      <Avatar sx={{ width: "100px", height: "100px" }}>
        <Box
          component="img"
          src={photoUrl}
          alt=""
          sx={{
            width: "100%",
          }}
        />
      </Avatar>
      <Typography
        onClick={goChart}
        sx={{
          fontSize: "40px",
          lineHeight: "49px",
          fontWeight: "800",
          cursor: "pointer",
          color: profileTheme.black,
          letterSpacing: "-1px",
        }}
      >
        $
        {totalShareValue.toLocaleString(undefined, {
          minimumFractionDigits: 2,
        })}
      </Typography>
      <Typography
        sx={{
          fontSize: "12px",
          lineHeight: "15px",
          fontWeight: "600",
          color: profileTheme.black,
          opacity: "0.5",
          textTransform: "uppercase",
          letterSpacing: "1px",
        }}
      >
        Total shares value
      </Typography>
    </Box>
  );
};

export default UserProfileHead;
