import React, { Fragment } from "react";
import { HomeAppBar } from "../components/AppBar";
import Header from "../components/Header/index";
import { AboutUs } from "../components/Open";


const AboutPage = () => {

  return (
    <Fragment>
      <HomeAppBar position="absolute"/>
      <Header pageTitle="About" />
      <AboutUs /> 
    </Fragment>
  );
};

export default AboutPage;
