import { DefaultActivity, EmptyEnterpriseObject, PAGE_NAME } from "./constant";
// import demoWE from "./demoWE.json";

export const enterpriseInitialState = {
  isLoading: true,
  tabValue: 0,
  secondTabValue: 3,
  userfetched: false,
  registeredUser: false,
  name: "", // Name of the user
  username: "", // user id
  photo: "/images/dashboard1_user.png",
  join_splash: true,
  authState: false,
  enterprises: [
    // demoWE,
  ],
  myProposals: [],
  tempEnterprise: EmptyEnterpriseObject,
  toEditShareholder: -1,
  toEditAdmin: -1,
  pageName: PAGE_NAME.id.home,
  totalSharePrice: 0,
};

export const notificationInitialState = {
  open: false,
  type: "info",
  message: "",
  timeout: 5000,
  detail: "",
};

export const sharesInitialState = {
  isLoading: true,
  data: []
};

export const activityInitialState = [DefaultActivity];
