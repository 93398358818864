import { InputLabel as MuiInputLabel, styled } from "@mui/material";

const InputLabel = styled(MuiInputLabel)(({ theme }) => (
{
  "&": {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#4B4749",
    transform: "translate(0, -1.5px) scale(1)",
    whiteSpace: "normal",
  }
}));

export default InputLabel;
