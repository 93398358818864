import { ArrowForwardIos, Person } from "@mui/icons-material";
import {
  Avatar,
  Divider,
  IconButton,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React from "react";

const Item = ({ avatar, title, content, onClick }) => {
  return (
    <>
      <ListItemButton
        component="div"
        sx={{
          px: 3,
          py: 1,
        }}
        onClick={() => {
          onClick();
        }}
      >
        <ListItemAvatar sx={{ pr: 2 }}>
          <Avatar sx={{ width: "63px", height: "63px" }}>
            {avatar === "" ? (
              <Person fontSize="large" htmlColor="#4B4749" />
            ) : (
              <img alt="" src={avatar} style={{ width: "100%" }} />
            )}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={title}
          secondary={content}
          primaryTypographyProps={{
            fontFamily: "Montserrat",
            fontSize: "15px",
            fontWeight: "600",
            lineHeight: "18px",
            letterSpacing: "0px",
            textAlign: "left",
          }}
          secondaryTypographyProps={{
            mt: 1,
            fontFamily: "Montserrat",
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "15px",
            letterSpacing: "0px",
            textAlign: "left",
          }}
          sx={{
            display: "block",
            width: "100%",
          }}
        />
        <IconButton edge="end" aria-label="delete">
          <ArrowForwardIos fontSize="small" />
        </IconButton>
      </ListItemButton>
      <Divider />
    </>
  );
};

export default Item;
