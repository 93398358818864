import React, { Fragment } from "react";
import { HomeAppBar } from "../components/AppBar";
import Header from "../components/Header/index";
import { Landing } from "../components/Open";

const Index = () => {

  return (
    <Fragment>
      <HomeAppBar position="absolute" />
      <Header pageTitle="Home" />
      <Landing />
    </Fragment>
  );
};

export default Index;
