import { Box } from "@mui/material";
import React from "react";
import Xarrow, { Xwrapper } from "react-xarrows";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import PButton from "./PButton";

Xarrow.defaultProps = {
  strokeWidth: 2,
  path: "grid",
  headShape: "arrow1",
  headSize: 4,
  animateDrawing: false,
  zIndex: 1,
};

const JoinProcess = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "30px",
      }}
    >
      <Xwrapper>
        <PButton id="request-to-join">Request to Join</PButton>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "30px",
            px: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "15px",
            }}
          >
            <PButton
              id="buy-from-orderbook"
              sx={{
                backgroundColor: "#FFDB0A",
                color: "#000",
              }}
            >
              Buy from Order Book
            </PButton>
            <PButton
              id="buy-and-mint"
              sx={{
                backgroundColor: "#FFDB0A",
                color: "#000",
              }}
            >
              Buy and Mint
            </PButton>
            <PButton
              id="request-share-grant"
              sx={{
                backgroundColor: "#FFDB0A",
                color: "#000",
              }}
            >
              Request Share Grant
            </PButton>
          </Box>
          <PButton id="proposal-created" sx={{ backgroundColor: "#000" }}>
            Proposal Created
          </PButton>
          <PButton
            id="shareholders-vote-to-approve"
            sx={{ backgroundColor: "#000" }}
          >
            Shareholders Vote to Approve
          </PButton>
          <PButton id="membership-granted" sx={{ backgroundColor: "#455A64" }}>
            Membership Granted
          </PButton>
        </Box>
        <Xarrow
          color="#3D61B0"
          startAnchor="bottom"
          endAnchor="top"
          start="request-to-join"
          end="request-share-grant"
        />
        <Xarrow
          color="#3D61B0"
          startAnchor="bottom"
          endAnchor="top"
          start="request-to-join"
          end="buy-and-mint"
        />
        <Xarrow
          color="#3D61B0"
          startAnchor="bottom"
          endAnchor="top"
          start="request-to-join"
          end="buy-from-orderbook"
        />
        <Xarrow
          color="#3D61B0"
          startAnchor="bottom"
          endAnchor="top"
          start="buy-and-mint"
          end="proposal-created"
        />
        <Xarrow
          color="#3D61B0"
          startAnchor="bottom"
          endAnchor="top"
          start="request-share-grant"
          end="proposal-created"
        />
        <Xarrow
          color="#3D61B0"
          startAnchor="bottom"
          endAnchor="top"
          start="proposal-created"
          end="shareholders-vote-to-approve"
        />
        <Xarrow
          color="#3D61B0"
          startAnchor="bottom"
          endAnchor="top"
          start="shareholders-vote-to-approve"
          end="membership-granted"
        />
        <Xarrow
          color="#3D61B0"
          startAnchor="left"
          endAnchor="left"
          start="buy-from-orderbook"
          end="membership-granted"
          _cpx1Offset={-10}
          _cpx2Offset={-10}
        />
      </Xwrapper>
    </Box>
  );
};

export default JoinProcess;
