import { Box, Typography } from "@mui/material";
import React from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

// const pages = [PAGE_NAME.id.home, PAGE_NAME.id.about];
const landingTheme = {
  primary: "#FFDB0A",
  secondary: "#3D61B0",
  black: "#0D141E",
  fontSize: { xs: "14px", md: "18px" },
  lineHeight: { xs: "17px", md: "180.5%" },
};

const SideItem = ({ iconSrc, title, content, bgColor = "#FBFF23" }) => {
  const landingText = {
    fontSize: { xs: title === "Shareholders" ? "11px" : "14px", md: "16px" },
    lineHeight: { xs: "20px", md: "24px" },
  }
  return (
    <Box
      sx={{
        width: "90% !important",
        height: "550px !important",
        p: { xs: "40px 0", md: "40px 35px 35px" },
        mx: "5%",
        mt: "10px",
        borderRadius: { xs: "5px", md: "8px" },
        background: `linear-gradient(153.81deg, ${bgColor} -78.74%, rgba(253, 255, 147, 0) 117.76%);`,
        maxHeight: "350px",
        overflow: "scroll",
      }}
    >
      <Box
        sx={{
          display: "flex",
          mb: { xs: "35px", md: "30px" },
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          component="img"
          src={iconSrc}
          alt=""
          sx={{
            mr: "15px",
            width: "auto !important",
            margin: "0px 20px 0px 0px !important",
          }}
        />
        <Typography
          sx={{
            fontSize: landingTheme.fontSize,
            lineHeight: { xs: "17px", md: "22px" },
            fontWeight: "600",
          }}
        >
          {title}
        </Typography>
      </Box>
      <Typography
        sx={{
          px: "31px",
          ...landingText,
        }}
      >
        {content}
      </Typography>
    </Box>
  );
};

export default SideItem;
