import { Box, Container } from "@mui/material";
import React from "react";

export default function ListItem(props) {
  const { children, ...other } = props;
  return (
    <Box
      {...other}
      sx={{
        py: "10px",
        borderBottom: "solid 1px #EEEEEE",
        cursor: "pointer",
      }}
    >
      <Container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {children}
      </Container>
    </Box>
  );
}
