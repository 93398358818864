import { ArrowBackIosNewRounded, ContentCopy } from "@mui/icons-material";
import {
  Avatar,
  Box,
  IconButton, Typography
} from "@mui/material";
import copy from "copy-to-clipboard";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import store from "../../constants/store";
import {
  PAGE_NAME,
  STORE_KEYS
} from "../../constants/store/constant";
import { useAutoConnect } from "../../utils/AutoConnect";
import { shortAddress } from "../../utils/shortAddress";
import SelectWalletModal from "../Dialog/SelectWalletModal";

const WEProfileAppBar = ({
  logo,
  backgroundUrl,
  isAdmin,
  name,
  ID,
  description,
  back,
  SecondIcon,
  secondAction,
  secondComponent,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { account, onDisconnect } = useAutoConnect();
  const [anchorEl, setAnchorEl] = useState(null);
  const [copied, setCopied] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [enterprises, , updateEnterprises] = store.useState(
    STORE_KEYS.id.enterprises
  );


  React.useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 1500);
    }
  }, [copied]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogin = () => {
    if (account && enterprises.registeredUser) {
      navigate(`/user/${account}`);
    } else {
      setIsOpen(true);
    }
  };

  const Close = () => {
    setIsOpen(false);
  };

  const handleNav = (typee) => () => {
    switch (typee) {
      case "login":
        handleLogin();
        break;
      case "home":
        updateEnterprises((prev) => {
          prev.pageName = PAGE_NAME.id.home;
          return prev;
        });
        navigate("/");
        break;

      case "setting":
        updateEnterprises((prev) => {
          prev.pageName = PAGE_NAME.id.setting;
          return prev;
        });
        navigate("/setting");
        break;

      case "about":
        updateEnterprises((prev) => {
          prev.pageName = PAGE_NAME.id.about;
          return prev;
        });
        navigate("/about");
        break;

      case "dashboard":
        updateEnterprises((prev) => {
          const index = prev.enterprises?.findIndex(
            (enterprise) =>
              enterprise.info.wepID === prev.tempEnterprise.info.wepID
          );
          if (index >= 0) {
            prev.enterprises[index] = prev.tempEnterprise;
            return prev;
          }
        });
        navigate(`/user/${account}`);
        break;

      case "disconnect":
        onDisconnect()
        break;

      case "faq":
        navigate("/faq");
        break;

      default:
        break;
    }
    handleClose();
  };


  return (
    <Box sx={{ background: "#ffffff", pb: 2 }}>
      <Box
        position="relative"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "203px",
          backgroundColor: "#FFDB0A"
        }}
      >
        <img
          alt="logo"
          src={backgroundUrl ? backgroundUrl : "/images/earth.svg"}
          style={{ width: "100%", position: "absolute", height: "100%", top: 0, left: 0, objectFit: "cover" }}
        />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ position: "absolute", left: "16px", top: "20px", width: "74px", height: "30px", backgroundColor: "#ffffff", borderRadius: "15px" }}
        >
          <IconButton
            onClick={() => {
              back();
            }}
            sx={{ '&:hover': { background: "none" }, '&:active': { background: "none" }, "& .MuiTouchRipple-child": { borderRadius: "primary" } }}
          >
            <ArrowBackIosNewRounded fontSize="small" sx={{ height: "10px" }} htmlColor="#28282B" />
            <Typography sx={{ fontWeight: 500, fontSize: "12px", lineHeight: "15px", color: "#28282B" }}>Back</Typography>
          </IconButton>
        </Box>
        {isAdmin && SecondIcon && (<Box sx={{ position: "absolute", right: "16px", top: "20px", width: "74px", height: "30px", backgroundColor: "#FFDB0A", borderRadius: "15px" }}>
          <IconButton
            onClick={() => {
              if (secondAction) secondAction();
            }}
            sx={{ display: "flex", width: "100%", columnGap: "7px", '&:hover': { background: "none" }, "& .MuiTouchRipple-child": { background: "none" } }}
          >
            <Box sx={{ width: "12px", height: "12px" }} component="img" src="/images/carbon_edit.svg"></Box>
            <Typography sx={{ fontWeight: 500, fontSize: "12px", lineHeight: "15px", color: "#28282B" }}>Edit</Typography>
          </IconButton>
        </Box>)}
        <Box sx={{ p: 0, position: "absolute", right: "14px", top: "14px" }}>
          {secondComponent}
        </Box>

        {logo !== "" && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Avatar sx={{ width: "98px", height: "98px", top: "calc(100% - 45px)", position: "absolute", border: "none" }}>
              <Box component="img" src={logo} sx={{ width: "100%" }} />
            </Avatar>
          </Box>
        )}

        <SelectWalletModal isOpen={isOpen} closeModal={Close} />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyItems="center"
        sx={{ mt: "45px" }}
      >
        <Typography
          variant="h2"
          sx={{ fontSize: "16px", fontWeight: 600, lineHeight: "20px", mt: "19px", color: "#28282B" }}
        >
          {name}
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "12px",
            fontWeight: "500",
            lineHeight: "15px",
            textAlign: "center",
            mt: "8px",
            color: "#FF6142",
          }}
          onClick={() => { copy(ID); setCopied(true); }}
          className="tooltip"
        >
          {shortAddress(ID)}
          <IconButton
            size="small"
            edge="end"
            color="primary"
            aria-label="menu"
            onClick={() => {
              copy(ID);
              setCopied(true);
            }}
          >
            <ContentCopy
              fontSize="small"
              htmlColor="dashboardTheme.gray"
            />
          </IconButton>
          <span
            className="tool-tip-text"
            style={{
              visibility: copied ? "visible" : "none",
              opacity: copied ? 1 : 0,
            }}
          >
            copied!
          </span>
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "22px",
          mt: "12px",
          color: "#28282B",
          mx: '16px',
        }}
      >
        {description ? description : `Coca-Cola Enterprises was a marketer, producer,
          and distributor of Coca-Cola products. It was
          formerly the anchor bottler for Western Europe and
          most of North America.`}
      </Typography>
    </Box>
  );
};

export default WEProfileAppBar;
