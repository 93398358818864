import { ThemeProvider } from "@mui/material";
import { Container } from "@mui/system";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SnackbarProvider } from "notistack";
import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
//import { Notification } from "./components/Notification";
import reportWebVitals from "./report/reportWebVitals";
import { theme } from "./theme";
import { AutoConnectProvider } from "./utils/AutoConnect";

import "./styles/font.css";
import "./styles/globals.css";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));



root.render(
  <Container id="app-container">
    <Router>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={3}>
            {/*<AutoConnectProvider>*/}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <App />
                {/* <ReactQueryDevtools /> */}
                {/*<Notification />*/}
              </LocalizationProvider>
            {/*</AutoConnectProvider>*/}
          </SnackbarProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </Router>
  </Container>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
