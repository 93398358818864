import { Grid } from "@mui/material";
import React from "react";

const CarouselItem = ({ children }) => {
  return (
    <Grid
      container
      key="content"
      rowSpacing={"16px"}
      sx={{
        px: "27px",
        mt: "0px",
      }}
    >
      {children}
    </Grid>
  );
};

export default CarouselItem;
