import React from "react";
import "react-phone-input-2/lib/style.css";
import GooglePlaceAutocomplete from "./GooglePlacesAutocomplete";

const AddressInput = ({ address, onChange }) => {
  return (
    <GooglePlaceAutocomplete
      apiKey={process.env.REACT_APP_GOOGLEMAP_APIKEY}
      autocompletionRequest={{
        componentRestrictions: {
          country: ["us", "ca"],
        },
      }}
      selectProps={{
        value: address,
        onChange: (addr) => {
          onChange(addr);
        },
      }}
    />
  );
};

export default AddressInput;
