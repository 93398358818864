import { styled } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';

export const convertRound = (rounded) => {
  switch(rounded) {
    case 'xs':
      return '4px'
    case 'sm':
      return '8px'
    case 'md':
      return '12px'
    case 'lg':
      return '16px'
    case 'xl':
      return '24px'
  }
}

export const fontForSize = (size) => {
  switch(size) {
    case 'small':
      return '12px'
    case 'medium':
      return '16px'
    case 'large':
      return '20px'
  }
}

const WELoadingButton = styled(LoadingButton)(({theme, type, variant, width, rounded, size, loading}) => ({
  borderRadius: `${convertRound(rounded)} !important`,
  color: type ? loading ? theme.palette[type].main : variant === 'outlined' ? theme.palette[type].main : theme.palette[type].contrastText : '',
  backgroundColor: variant === 'outlined' ? 'transparent' : type ? theme.palette[type].main : '',
  borderColor: type ? variant === 'outlined' ? theme.palette[type].light : theme.palette[type].main : '',
  borderWidth: variant === 'outlined' ? '1px': '0px',
  width: `${width}px`,
  padding: '12px 20px',
  fontSize: fontForSize(size),
  "&:hover": {
    color: type ? loading ? theme.palette[type].main : variant === 'outlined' ? theme.palette[type].dark: theme.palette[type].contrastText : '',
    backgroundColor: variant === 'outlined' ? 'transparent': type ? theme.palette[type].dark : '',
    borderColor: type ? variant === 'outlined' ? theme.palette[type].dark : theme.palette[type].main : '',
    // opacity: variant === 'outlined' ? 1 : 0.9
  },
  "&:active": {
    color: type ? loading ? theme.palette[type].main : variant === 'outlined' ? theme.palette[type].dark: theme.palette[type].contrastText : '',
    backgroundColor: variant === 'outlined' ? 'transparent': type ? theme.palette[type].dark : '',
    borderColor: type ? variant === 'outlined' ? theme.palette[type].dark : theme.palette[type].main : '',
    // opacity: variant === 'outlined' ? 1 : 0.9
  }
}))

export default WELoadingButton;