import { Add, ExpandLess, ExpandMore, HorizontalRule, Search } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Collapse,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  List,
  Slider,
  TextField,
  Typography
} from "@mui/material";
import { useWeb3React } from "@web3-react/core";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "../../components/Input";
import { CreateAppBar } from "../AppBar";
import { JoinModal } from "../Dialog";
import Item from "./Item";

import store from "../../constants/store";
import { STORE_KEYS } from "../../constants/store/constant";
import { useNotification } from "../Notification/hook";

const DateType = {
  Start: 0,
  End: 1,
}

const DashBoard2 = () => {
  const { account } = useWeb3React();
  const [enterprises, , updateEnterprises] = store.useState(
    STORE_KEYS.id.enterprises
  );
  const navigate = useNavigate();
  const { displayWarning } = useNotification();

  const [isMine, setIsMine] = useState(false);
  const [idx, setIdx] = useState(-1);
  const [dlgOpened, setDlgOpened] = useState(false);
  const [searchWord, setSearchWord] = useState("");
  const [maxPrice, setMaxPrice] = useState(0)
  const [startPrice, setStartPrice] = useState(0)
  const [endPrice, setEndPrice] = useState(0)

  const [startDate, setStartDate] = useState(dayjs("2000-01-01"));
  const [endDate, setEndDate] = useState(dayjs());

  const [maxMemNum, setMaxMemNum] = useState(0)
  const [startMemNum, setStartMemNum] = useState(0)
  const [endMemNum, setEndMemNum] = useState(0)
  const [viewFilter, setViewFilter] = useState(false)
  const [checked, setChecked] = useState(false);

  const newHandler = () => {
    navigate("/createEnterpriseSplash");
  };

  const searchHandler = (evt) => {
    if (evt.key === "Enter") {
      // Prevent's default 'Enter' behavior.
      evt.preventDefault();
      // your handler code
    }
  };

  const joinWE = async (enterprise, idx, isJoined = true, comment = "") => {
    // let temp = EmptyEnterprise();
    // temp = JSON.parse(JSON.stringify(enterprise))
    // temp.joined = isJoined

    // if (!temp.fetched) {
    //   try {
    //     const meta = await getAwsMetaData(enterprise.info.metaUrl)
    //     const data = meta.data
    //     console.log("metadata", meta)
    //     temp.fetched = true

    //     temp.admins = data.admins;
    //     temp.businessUpdates = data.businessUpdates;

    //     temp.info.description = data.info.description;
    //     temp.info.isRegisterd = data.info.isRegisterd;
    //     temp.info.logo = data.info.logo;
    //     temp.info.memNum = data.info.memNum;
    //     temp.info.name = data.info.tokenName;
    //     temp.info.price = data.info.price;
    //     temp.info.website = data.info.website;

    //     if (temp.admins[0].walletAddr.toUpperCase() === account.toUpperCase()) {
    //       temp.mine = true;
    //     }
    //     temp.orders = data.orders;
    //     temp.proposals = [...data.proposals, ...temp.proposals];
    //     temp.shareholders = data.shareholders;
    //     temp.transactions = data.transactions;

    //     // temp.proposals = []
    //     // let res = await getProposalsForEnterprise(temp.info.address)
    //     // if (res?.data?.exists) {
    //     //   try {
    //     //     const data = res.data.data;
    //     //     for (let proposal of data) {
    //     //       let votesYes = 0;
    //     //       let votesNo = 0;
    //     //       let isApproved = false;
    //     //       if (proposal?.votes) {
    //     //         try {
    //     //           const votes = JSON.parse(proposal?.votes);
    //     //           console.log(votes);
    //     //           const votesKey = Object.keys(votes);
    //     //           for (let key of votesKey) {
    //     //             if (key.toUpperCase() === account.toUpperCase()) {
    //     //               isApproved = true;
    //     //             }
    //     //             if (votes[key]?.approve === "NO") {
    //     //               votesNo++;
    //     //             } else if (votes[key]?.approve === "YES") {
    //     //               votesYes++;
    //     //             }
    //     //           }
    //     //         } catch (e) {}
    //     //       }

    //     //       let prp = EmptyProposal()
    //     //       prp.id = proposal?.proposalIndex
    //     //       prp.name = "Join World Enterprise Request"
    //     //       prp.type = ProposalType.SHAREHOLDER
    //     //       prp.votesYes = votesYes
    //     //       prp.votesNo = votesNo
    //     //       prp.walletAddr = proposal?.proposer
    //     //       prp.isApproved = isApproved
    //     //       prp.comment = proposal?.commentUrl
    //     //       temp.proposals.push(prp);
    //     //     }
    //     //   } catch (e) {}
    //     // }
    //     // let prp = EmptyProposal()
    //     // prp.id = Date.now()
    //     // prp.name = "Join World Enterprise Request"
    //     // prp.walletAddr = account
    //     // prp.comment = comment
    //     // temp.proposals.push(prp);
    //   } catch (error) {
    //     console.error(error)
    //   }
    //   console.log(temp)
    // }

    updateEnterprises((prev) => {
      // prev.enterprises[idx] = temp;
      prev.tempEnterprise = enterprise;
      return prev;
    });
    navigate(`/wepID/dashboard`, {
      state: { wepID: enterprise.info.wepID, weBack: "/start" },
    });
  };

  const onClickItem = (idx) => () => {
    setIdx(idx);
    if (enterprises.enterprises[idx]) {
      if (
        !enterprises.enterprises[idx].isShareholder &&
        !enterprises.enterprises[idx].joined
      ) {
        setDlgOpened(true);
      } else {
        let enterprise = { ...enterprises.enterprises[idx] };
        joinWE(enterprise, idx, false);
      }
    }
  };

  const requestHandler =
    (idx) =>
      ({ comment }) => {
        // create a new proposal and add it
        let enterprise = { ...enterprises.enterprises[idx] };
        const joinProposal = {
          id: Date.now(),
          type: "join",
          votesYes: 0,
          votesNo: 0,
          walletAddr: account || "",
          name: "",
          isApproved: false,
          comment: comment,
        };
        enterprise.proposals = [...enterprise.proposals, joinProposal];
        // join
        setTimeout(() => {
          joinWE(enterprise, idx, false);
        }, 3000);
      };

  const switchHandler = (isMine) => {
    setIsMine(isMine);
  };

  const handleSearchInput = (evt) => {
    const value = evt.currentTarget.value;
    setSearchWord(value);
  };

  const backHandle = () => {
    navigate(`/user/${account}`);
  };

  const resetFilter = () => {
    let mxPrice = 0
    let mxCreatedAt = 0
    let mnCreatedAt = Date.now()
    let mxMemNum = 0

    enterprises.enterprises.map((ent) => {
      if (ent.info.price > mxPrice) mxPrice = ent.info.price
      if (ent.info.created_at > mxCreatedAt) mxCreatedAt = ent.info.created_at
      if (ent.info.created_at < mnCreatedAt) mnCreatedAt = ent.info.created_at
      if (ent.info.memNum > mxMemNum) mxMemNum = ent.info.memNum
    })
    setMaxPrice(mxPrice)
    setStartPrice(0)
    setEndPrice(mxPrice)
    setStartDate(dayjs(mnCreatedAt * 1000))
    setEndDate(dayjs(mxCreatedAt * 1000))
    setMaxMemNum(mxMemNum)
    setStartMemNum(0)
    setEndMemNum(mxMemNum)
  }

  const onChangePrice = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) return
    setStartPrice(newValue[0])
    setEndPrice(newValue[1])
  }

  const onChangeMemNum = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) return
    setStartMemNum(newValue[0])
    setEndMemNum(newValue[1])
  }

  const handleDateChange = (type, startDate, endDate) => (date) => {
    switch (type) {
      case DateType.Start:
        let newStartDate = date.clone().set('hour', 0).set('second', 0).set('millisecond', 0)
        if (newStartDate.isAfter(endDate)) {
          displayWarning({
            message: "Invalid start date. Start date is after end date.",
            timeout: 5000,
          })
        } else {
          setStartDate(newStartDate)
        }
        break;
      case DateType.End:
        let newEndDate = date.clone().set('hour', 23).set('second', 59).set('millisecond', 999)
        if (newEndDate.isBefore(startDate)) {
          displayWarning({
            message: "Invalid end date. End date is before start date.",
            timeout: 5000,
          })
        } else {
          setEndDate(newEndDate)
        }
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    resetFilter()
  }, [enterprises.enterprises])

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  return (
    <>
      <CreateAppBar
        title={"Search Enterprises"}
        close={backHandle}
        helpEnabled={false}
        bgColor="#F4F8FF"
      />
      <Container
        sx={{
          display: "flex",
          mt: 4,
          px: 2,
          py: 0,
          width: "100%"
        }}
        maxWidth={false}
      >
        <Grid
          component="h2"
          container
          onClick={() => {
            switchHandler(!isMine);
          }}
          sx={{
            width: "100%",
            lineHeight: "40px",
            py: 0,
            my: 0,
            mr: 2,
            ml: 0,
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "15px",
            textAlign: "center",
            borderRadius: "8px",
            border: "1px solid #E3E8EB",
            cursor: "pointer",
          }}
        >
          <Grid
            item
            xs={6}
            component="div"
            style={{
              display: "inline-block",
              borderRadius: "8px",
              backgroundColor: isMine ? "#3D61B0" : "#ffffff",
              color: isMine ? "#ffffff" : "#96A3AA",
            }}
          >
            My Enterprises
          </Grid>
          <Grid
            item
            xs={6}
            component="div"
            style={{
              display: "inline-block",
              borderRadius: "8px",
              backgroundColor: isMine ? "#ffffff" : "#3D61B0",
              color: isMine ? "#96A3AA" : "#ffffff",
            }}
          >
            All Enterprises
          </Grid>
        </Grid>
        <Button
          sx={{
            // marginLeft: "-51px",
            // paddingLeft: "-51px",
            p: 0,
            border: "1px solid #3D61B0",
            borderRadius: "8px",
            backgroundColor: "#ffffff",
            boxShadow: "0",

            minWidth: "42px",
            // "& .MuiTouchRipple-root": {
            //   width: "40px!important",
            //   background: "#ff0000",
            // },
            ":hover": {
              backgroundColor: "#ffffff",
            },
          }}
          onClick={() => {
            newHandler();
          }}
        >
          <Add
            fontSize="large"
            htmlColor="#3D61B0"
            sx={{
              p: 0,
            }}
          />
        </Button>
        {/* <IconButton>
        </IconButton> */}
      </Container>
      <Container sx={{
        display: "flex",
        mt: 2,
        px: 2,
        width: "100%"
      }} maxWidth={false}>
        {/* <Paper
          component="div"
          sx={{ display: 'flex', alignItems: 'center', width: "100%" }}
        > */}

        {enterprises.enterprises && (
          <Collapse
            orientation="horizontal"
            in={checked}
            collapsedSize={30}

          >
            <Autocomplete
              fullWidth
              freeSolo
              id="search"
              disableClearable
              options={enterprises.enterprises
                .filter((enterprise) => !isMine || (isMine && enterprise.mine))
                .map((enterprise) => enterprise.info.name)}
              sx={{
                "&.MuiAutocomplete-root": {
                  width: "calc(100vw - 100px)",
                  maxWidth: "1100px"
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                    startAdornment: (
                      <InputAdornment position="start" onClick={handleChange}>
                        <Search htmlColor="#96A3AA" />
                      </InputAdornment>
                    ),
                  }}
                  value={searchWord}
                  onChange={handleSearchInput}
                  placeholder="Search Enterprise"
                  sx={{
                    height: "42px",
                    backgroundColor: "#FFFFFFFF",
                    border: !checked ? "none" : "1px solid #E3E8EB",
                    borderRadius: !checked ? "none" : "8px",

                    "& .MuiInputBase-root:hover fieldset": {
                      border: "none"
                    },

                    "& input": {
                      // height: "42px",
                      // backgroundColor: "#FFFFFFFF",
                      boxShadow: "none",
                      color: "#4B4749",
                      fontSize: "15px!important",
                      fontFamily: "Montserrat",
                      fontWeight: 500,
                      lineHeight: "18.29px",
                      padding: "0!important",
                    },
                    "& fieldset": {
                      height: "42px",

                      border: "1px solid #FFFFFFFF",
                      "& fieldset:focus": {
                        border: "1px solid #ced4da !important",
                      },
                      "&:focus": {
                        // boxShadow: `0 0 0 0.2rem`,
                      },
                    },
                  }}
                />

              )}
              renderOption={(props, option, { inputValue }) => {
                const matches = match(option, inputValue, { insideWords: true });
                const parts = parse(option, matches);

                return (
                  <>
                    <Divider />
                    <li {...props} style={{ paddingLeft: "9px" }}>
                      <Search
                        fontSize="small"
                        htmlColor="#96A3AA"
                        sx={{ mr: 1 }}
                      />
                      <div>
                        {parts.map((part, index) => (
                          <span
                            key={index}
                            style={{
                              fontWeight: part.highlight ? 700 : 400,
                            }}
                          >
                            {part.text}
                          </span>
                        ))}
                      </div>
                    </li>
                  </>
                );
              }}
              onKeyDown={searchHandler}
              onSubmit={() => { }}
            />
          </Collapse>
        )}

        {
          !viewFilter ? (
            <IconButton
              onClick={() => {
                setViewFilter(true)
              }}
              sx={{ marginLeft: "12px" }}
            >
              <ExpandMore htmlColor="#3D61B0" />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => {
                setViewFilter(false)
              }}
              sx={{ marginLeft: "12px" }}
            >
              <ExpandLess htmlColor="#3D61B0" />
            </IconButton>
          )
        }

        {/* </Paper> */}
      </Container>
      {viewFilter && (
        <>
          <Container sx={{ display: "flex", flexDirection: "row", mt: 2, px: 2, width: "100%" }}>
            <Typography variant="h3" sx={{ width: "150px", alignSelf: "self-end" }}>
              Price
            </Typography>
            <Slider
              getAriaLabel={() => 'Price range'}
              value={[startPrice, endPrice]}
              onChange={onChangePrice}
              valueLabelDisplay="on"
              min={0}
              max={maxPrice}
              step={0.00001}
              color="secondary"
              disableSwap
              sx={{ mt: 5, mx: 2 }}
            />
          </Container>
          <Container sx={{ display: "flex", flexDirection: "row", mt: 2, px: 2, width: "100%" }}>
            <Typography variant="h3" sx={{ minWidth: "150px", alignSelf: "self-end" }}>
              Members Number
            </Typography>

            <Slider
              getAriaLabel={() => 'Price range'}
              value={[startMemNum, endMemNum]}
              onChange={onChangeMemNum}
              valueLabelDisplay="on"
              min={0}
              max={maxMemNum}
              step={1}
              color="secondary"
              disableSwap
              sx={{ mt: 5, mx: 2 }}
            />
          </Container>
          <Container
            sx={{
              px: 2,
              mt: 2,
              display: "flex",
              flexDirection: "row",
              maxWidth: "540px!important",
            }}
          >
            <DatePicker
              onChange={handleDateChange(DateType.Start, startDate, endDate)}
              value={startDate}
            />
            <div
              style={{
                height: "100%",
                margin: "auto",
              }}
            >
              <HorizontalRule
                fontSize="small"
                htmlColor="#4B4749"
              />
            </div>
            <DatePicker
              onChange={handleDateChange(DateType.End, startDate, endDate)}
              value={endDate}
            />
          </Container>
          <Container sx={{
            display: "flex",
            mt: 2,
            px: 2,
            width: "100%"
          }}>
            <Button
              onClick={resetFilter}
              sx={{
                mx: "auto",
              }}
            >
              Reset filter
            </Button>
          </Container>
        </>
      )}
      <Grid
        container
        component="main"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          p: 0,
          // mt: 4,
        }}
      >
        <List dense={true} sx={{
          width: "100%",
          pt: 2,
          px: 0
        }}>
          <Divider />
          {enterprises.enterprises &&
            enterprises.enterprises.map((enterprise, idx) => {
              if (
                (!isMine || (isMine && enterprise.isShareholder)) &&
                enterprise.info.name
                  .toLowerCase()
                  .indexOf(searchWord.toLowerCase()) > -1 && (enterprise.info.price >= startPrice) &&
                (enterprise.info.price <= endPrice) &&
                (enterprise.info.memNum >= startMemNum) &&
                (enterprise.info.memNum <= endMemNum) &&
                (enterprise.info.created_at >= startDate.unix()) &&
                (enterprise.info.created_at <= endDate.unix())
              ) {
                return (
                  <Item
                    key={idx}
                    avatar={enterprise.info.logo}
                    title={enterprise.info.name}
                    content={enterprise.info.tokenName}
                    onClick={onClickItem(idx)}
                  />
                );
              }
              return null;
            })}
        </List>
      </Grid>
      {enterprises?.enterprises[idx] && (
        <JoinModal
          joinWE={joinWE}
          idx={idx}
          enterprise={enterprises.enterprises[idx]}
          wepID={enterprises.enterprises[idx].info.wepID}
          open={dlgOpened}
          onClose={() => {
            setDlgOpened(false);
          }}
          name={enterprises.enterprises[idx].info.name}
          memNum={enterprises.enterprises[idx].info.memNum}
          website={enterprises.enterprises[idx].info.website}
          description={enterprises.enterprises[idx].info.description}
          enterpriseAddress={enterprises.enterprises[idx].info.address}
          shareToBuy={150}
          offerPrice={0}
          request={requestHandler(idx)}
        />
      )}
    </>
  );
};

export default DashBoard2;