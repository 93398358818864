import {
  HelpOutlineOutlined
} from "@mui/icons-material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import HelpIcon from '@mui/icons-material/Help';
import {
  Container, IconButton,
  Toolbar,
  Typography
} from "@mui/material";
import React from "react";
import { ToolTip } from "../Dialog";

const AppBar = ({
  title,
  back,
  handle,
  type,
  position,
  toolTipTitle,
  toolTip = "",
  bgColor = "#FFFFFF",
}) => {
  return (
    <Container
      sx={{
        boxShadow: "none",
        backgroundColor: bgColor,
        position: position === "absolute" ? "absolute" : "relative",
        padding: "0",
      }}
    >
      <Toolbar sx={{ pt: 3 }}>
        {/* <IconButton
          size="large"
          edge="start"
          color="primary"
          aria-label="menu"
          onClick={() => {
            back();
          }}
        >
          <ArrowBack fontSize="medium" htmlColor="#4B4749" />
        </IconButton> */}
        <Typography
          variant="h2"
          component="div"
          sx={{
            flexGrow: 1,
            pr: type === "none" ? "36px!important" : "0px",
          }}
        >
          {title}
          {toolTip !== "" && (
            <ToolTip title={toolTip}>
              <IconButton size="small" color="primary">
                <HelpIcon fontSize="medium" color="primary" />
              </IconButton>
            </ToolTip>
          )}
        </Typography>
        {(type === "add" || type === "help") && (
          <ToolTip title={toolTipTitle}>
            <IconButton
              onClick={() => {
                if (handle !== undefined) handle();
              }}
            >
              {type === "add" && <AddCircleIcon sx={{ color: "black", fontSize: "30px" }} />}
              {type === "help" && <HelpOutlineOutlined />}
            </IconButton>
          </ToolTip>
        )}
      </Toolbar>
    </Container>
  );
};

export default AppBar;
