import React from "react"
import Tooltip from "../Dialog/ToolTip"

const SharePriceTooltip = ({ children }) => {
  return (
    <Tooltip title="Share value is the number of shares you own multiplied by the market price of one share">
      {children}
    </Tooltip>
  )
}

export default SharePriceTooltip