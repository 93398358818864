import React from "react";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";

export function useWeb3() {
  const [web3, setWeb3] = React.useState(null);
  const {connector: {provider} } = useWeb3React();

  React.useEffect(() => {
    const w3 = new Web3(provider)
    setWeb3(w3);
  }, [provider]);
  return web3;
}
