import { Box } from "@mui/material";
import React from "react";
import Xarrow, { Xwrapper } from "react-xarrows";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import PButton from "./PButton";

Xarrow.defaultProps = {
  strokeWidth: 2,
  path: "grid",
  headShape: "arrow1",
  headSize: 4,
  animateDrawing: false,
  zIndex: 1,
};

const CreateProcess = () => {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        width: "100%",
      }}
    >
      <Xwrapper>
        <PButton id="enter-business-detail">Enter Business Details</PButton>
        <PButton id="add-shareholders" sx={{ backgroundColor: "#000" }}>
          Add Shareholders
        </PButton>
        <PButton id="enter-token-supply" sx={{ backgroundColor: "#000" }}>
          Enter Token Supply
        </PButton>
        <PButton id="add-admins" sx={{ backgroundColor: "#000" }}>
          Add Admins
        </PButton>
        <PButton id="create-smart-contract" sx={{ backgroundColor: "#455A64" }}>
          Create Smart Contract and Save to Blockchain
        </PButton>
        <Xarrow
          color="#3D61B0"
          startAnchor="bottom"
          endAnchor="top"
          start="enter-business-detail"
          end="add-shareholders"
        />
        <Xarrow
          color="#3D61B0"
          startAnchor="bottom"
          endAnchor="top"
          start="add-shareholders"
          end="enter-token-supply"
        />
        <Xarrow
          color="#3D61B0"
          startAnchor="bottom"
          endAnchor="top"
          start="enter-token-supply"
          end="add-admins"
        />
        <Xarrow
          color="#3D61B0"
          startAnchor="bottom"
          endAnchor="top"
          start="add-admins"
          end="create-smart-contract"
        />
      </Xwrapper>
    </Box>
  );
};

export default CreateProcess;
