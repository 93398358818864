import React from "react";
import EnterpriseABI from "../constants/abi/enterprise.json";
import { useWeb3 } from "./web3";

export function useEnterprise(address) {
  const web3 = useWeb3();
  const [weEnterprise, setWeEnterprise] = React.useState();

  React.useEffect(() => {
    try {
      if (address) {
        setWeEnterprise(new web3.eth.Contract(EnterpriseABI, address));
      }
    } catch (e) {}
  }, [web3]); // eslint-disable-line react-hooks/exhaustive-deps
  return weEnterprise;
}
