import { Close } from "@mui/icons-material";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from "@mui/material";
import React from "react";
import DetailDialog, { WEModal } from "./DetailDialog";
import Info from "./Info";
import BtnCancel from "./BtnCancel";
import InfoAddr from "./InfoAddr";
import Transition from "./Transition";
import { ModalHead } from "../AppBar";

const ShareholderDetailModal = ({
  open,
  onClose,
  firstName,
  lastName,
  numOfShares,
  walletAddr,
  replace,
  remove,
}) => {
  return (
    <WEModal
      open={open}
      onClose={() => onClose()}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle sx={{ p: "0px !important" }} id="alert-dialog-title">
        <ModalHead
          title={"Shareholder"}
          close={() => onClose()}
          color='#FFDB0B'
        />
      </DialogTitle>
      <Divider />
      <DialogContent>
        <InfoAddr label="Wallet" value={walletAddr} />
        <Info label="Number of Shares" value={parseFloat(numOfShares)} />
        <Info label="Name" value={`${firstName} ${lastName}`} />
      </DialogContent>
      <Divider />
      <DialogActions>
        <BtnCancel onClick={() => replace()}>Replace</BtnCancel>
        <BtnCancel onClick={() => remove()}>Remove</BtnCancel>
      </DialogActions>
    </WEModal>
  );
};

export default ShareholderDetailModal;
