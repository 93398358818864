import {
  Container,
  DialogActions,
  DialogContent, DialogContentText, DialogTitle,
  Divider,
  Box
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { getRemainTimeToExecute, isReadyToExecute } from "../../utils/date";
import { shortAddress } from "../../utils/shortAddress";
import { WEModal } from "./DetailDialog";
import Info from "./Info";
import InfoAddr from "./InfoAddr";
import InfoVote from "./InfoVote";
import Transition from "./Transition";
import { WEButton } from "../Button";
import { ModalHead } from "../AppBar";

const linkStyle = {
  textDecoration: "underline",
  color: "blue",
  cursor: "pointer",
};

const ProposalDetailModal = ({
  open,
  handleClose,
  handleCommentOpen,
  reject,
  agree,
  execute,
  name,
  walletAddr,
  id,
  idx,
  commentUrl,
  comments,
  yesNum,
  noNum,
  isApproved,
  status,
  endTime,
}) => {
  const [remainTime, setRemainTime] = React.useState("");
  const [comment, setComment ] = useState("");
  React.useEffect(() => {
    const handler = setInterval(() => {
      if (parseInt(endTime) > 0) {
        setRemainTime(getRemainTimeToExecute(endTime));
      }
    }, 1000);

    return () => {
      clearInterval(handler);
    };
  }, [endTime]);

  useEffect(() => {
    if (commentUrl) {
      axios.get(commentUrl).then(res => {
        setComment(res.data)
      })
    }
  }, [commentUrl])

  return (
    <WEModal
      open={open}
      onClose={() => handleClose()}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle id="alert-dialog-title">
        <ModalHead
          title={"Proposal Detail"}
          close={() => handleClose()}
          color='#FFDB0B'
        />
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Info label="Name" value={name} />
        <InfoAddr label="Wallet" value={shortAddress(walletAddr)} addr={walletAddr.toLowerCase()}/>
        <Info label="Proposal ID" value={idx} />
        <Info label="Description" value={comment} />
        <i style={linkStyle} onClick={handleCommentOpen}>
            View Comments ({comments? comments.length : 0})
        </i>
        <Container
          component="span"
          sx={{
            display: "flex",
            margin: "12px 0px",
            p: 0,
          }}
        >
          <InfoVote type="yes" num={yesNum} />
          <InfoVote type="no" num={noNum} />
        </Container>
        {status === "" && !isReadyToExecute(endTime) && (
          <DialogContentText id="alert-dialog-description">
            {remainTime}
          </DialogContentText>
        )}
      </DialogContent>
      <Divider />
      <DialogActions sx={{display: "flex", justifyContent: "center"}}>
        <Box sx={{px: 2, display: "flex", flexDirection: "column", gap: 1, justifyContent: "center", width: "100%"}}>
          {status === "" && !isReadyToExecute(endTime) && (
            // <BtnOK disable={isApproved.toString()} onClick={() => agree()}>
            //   Agree
            // </BtnOK>
            <WEButton
              type="primary"
              variant = "outlined"
              width = {320}
              rounded = "xl"
              onClick={() => agree()}
            >
              Agree
            </WEButton>
          )}
          {status === "" && !isReadyToExecute(endTime) && (
            // <BtnCancel disable={isApproved.toString()} onClick={() => reject()}>
            //   Reject
            // </BtnCancel>
            <WEButton
              onClick={() => reject()}
              type="primary"
              variant = "outlined"
              rounded = "xl"
              size="medium"
              width = {320}
            >
              Reject
            </WEButton>
          )}
          {status === "" && isReadyToExecute(endTime) && (
            // <BtnOK disable={(status !== "").toString()} onClick={() => execute()}>
            //   Execute
            // </BtnOK>
            <WEButton
            onClick={() => execute()}
              type="primary"
              variant = "outlined"
              rounded = "xl"
              size="medium"
              width = {320}
            >
              Execute
            </WEButton>
          )}
        </Box>
      </DialogActions>
    </WEModal>
  );
};

export default ProposalDetailModal;
