import axios from "axios";
import { Buffer } from "buffer";
import { CID, create as ipfsHttpClient } from "ipfs-http-client";

export const toBase32 = (value) => {
  var cid = new CID(value);
  return cid.toV1().toBaseEncodedString("base32");
};

const projectId = process.env.REACT_APP_INFURA_PROJECT_ID;
const projectSecret = process.env.REACT_APP_INFURA_PROJECT_SECRET;
const auth =
  "Basic " + Buffer.from(projectId + ":" + projectSecret).toString("base64");

export const client = ipfsHttpClient({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
  headers: {
    authorization: auth,
  },
});

export const uploadToIPFS = async (data, callbackProgress) => {
  try {
    const added = await client.add(data, {
      progress: callbackProgress,
    });
    const path = toBase32(added.path);
    const ipfsUrl = `https://${path}.ipfs.infura-ipfs.io`;
    return ipfsUrl;
  } catch (e) {
    return "";
  }
};

export const ipfsUrlFromHash = (h) => {
  return "https://ipfs.io/ipfs/" + h;
};

export const ipfsHashFromMetauri = (uri) => {
  return uri.replace("https://", "").replace(".ipfs.infura-ipfs.io", "");
};

export const getIpfsData = (uri) => {
  return axios.get(uri).then((response) => {
    return response;
  });
};

export const getIpfsMetaData = (metaUri) => {
  let uri = ipfsUrlFromHash(ipfsHashFromMetauri(metaUri));
  return getIpfsData(uri);
};
